export interface FoldersAPIParamsGetFolders {
  assetId?: number;
  folderId?: number;
}

export type FoldersAPIRespGetFolders = {
  parent?: number;
  name: string | undefined;
  number: string | undefined;
  folders: Array<FolderProperties> | [];
};

export type FolderProperties = {
  id: number;
  name: string;
  number: string;
  lvl: number;
  isTransactionRelevant: boolean;
  isApproved: boolean;
  isNumbered: boolean;
  inOperation: number;
  updated: number;
  groupFolderRights: Record<Permissions, boolean>;
  documentCount: number;
  subFolderCount: number;
};

export enum Permissions {
  FOLDER_READ = 'folderRead',
  FOLDER_WRITE = 'folderWrite',
  FOLDER_DELETE = 'folderDelete',
  FOLDER_MARK = 'folderMark',
  FOLDER_APPROVE = 'folderApprove',
  FOLDER_HINT_READ = 'folderHintRead',
  DOCUMENT_READ = 'documentRead',
  DOCUMENT_WRITE = 'documentWrite',
  DOCUMENT_DELETE = 'documentDelete',
  PREVIEW_WATERMARK = 'previewWatermark',
  PREVIEW_ORIGINAL = 'previewOriginal',
  DOWNLOAD_WATERMARK = 'downloadWatermark',
  DOWNLOAD_ORIGINAL = 'downloadOriginal',
}

export interface FolderListProps {
  isLoading?: boolean;
  assetId?: number;
  folderId?: number;
}
