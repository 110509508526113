/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  style,
  color,
  ColorProps,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system';
import { css } from '@styled-system/css';

export interface TextProps extends SpaceProps, ColorProps, TypographyProps {
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  truncate?: boolean;
  className?: string;
  as?: string;
  role?: string;
}

const defaultStyles = {
  m: 0,
  fontWeight: 'normal',
  fontFamily: 'body',
  fontSize: 'inherit',
  lineHeight: 'base',
  color: 'text',
};

// text-transform isn't supported by styled-system atm
const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'text-transform',
});

const StyledText: React.FC<TextProps> = styled('p')(
  css(defaultStyles),
  variant({
    prop: 'truncate',
    variants: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }),
  color,
  typography,
  space,
  textTransform
);

const Text: React.FC<TextProps> = ({ children, ...props }) => (
  <StyledText {...props}>{children}</StyledText>
);

export default Text;
