import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { InvertedTheme } from 'theme/ThemeProvider';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'redux/mappers/Session';
import { MFC } from 'types/React';
import { Flex } from 'primitives';
import { LoadingOverlay } from 'components/Loading';
import { css } from '@styled-system/css';
import bg1460 from './images/bg@1460.jpg';
import bg768 from './images/bg@768.jpg';
import bg1200 from './images/bg@1200.jpg';

interface AuthRouteProps extends RouteProps {
  name: string;
}

const StyledPage = styled(Flex)(
  css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'background',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: [null, null, null, '100%'],
    backgroundImage: [`url(${bg768})`, `url(${bg1200})`, null, `url(${bg1460})`],
    overflowX: 'auto',
  })
);

const AuthRoute: MFC<AuthRouteProps> = ({ component, name = '', path }): JSX.Element => {
  const Page = component as React.FC;
  const loggedIn = useSelector(isLoggedIn);
  return (
    <InvertedTheme>
      <StyledPage data-test-id={`${name}--page`}>
        <Route
          path={path}
          render={(): JSX.Element => (!loggedIn ? <Page /> : <Redirect to="/cockpit" />)}
        />
      </StyledPage>
      <LoadingOverlay />
    </InvertedTheme>
  );
};

export default AuthRoute;

// track component's unnecessary re-renders
AuthRoute.whyDidYouRender = true;
