import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'redux/mappers/Session';
import Routes from 'helpers/Routes';

/**
 * If the user lands on the root route `/`
 * then, check if link has hash `#`
 * if so, try redirecting without the hash,
 * otherwise, redirect to the proper route
 * based on login status
 */
const RootRouter: React.FC = () => {
  const loggedIn = useSelector(isLoggedIn);

  if (!window.location.hash)
    return loggedIn ? <Redirect to={Routes.Cockpit} /> : <Redirect to={Routes.LoginPage} />;

  return <Redirect to={window.location.hash.substring(1)} />;
};

export default RootRouter;
