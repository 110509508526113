import { DocumentProperties, PreviewStatus } from 'types/Documents';
import { useTranslation } from 'react-i18next';

type ErrorMessage = { title: string; text: string; icon: string } | undefined;

// Set error message according to preview status
const usePageListErrorMessage = (document: DocumentProperties): ErrorMessage => {
  const { t } = useTranslation('document-organizer');
  const previewAvailable = document.previewStatus === PreviewStatus.generated;
  const previewAllowed = document.folder.groupFolderRights?.previewOriginal;

  if (previewAvailable && previewAllowed) return undefined;

  let errorMessage = {
    title: t('preview.preview_not_available'),
    text: '',
    icon: 'visibility_off',
  };

  const status = document?.previewStatus;

  if (!previewAllowed) {
    errorMessage = {
      title: t('preview.preview_not_available'),
      text: t('preview.you_do_not_have_permissions'),
      icon: 'block',
    };
  }

  if (status === PreviewStatus.impossible) {
    errorMessage = {
      title: t('preview.preview_not_available'),
      text: t('preview.the_file_type_is_not_supported'),
      icon: 'visibility_off',
    };
  }
  if (status === PreviewStatus.progress || status === PreviewStatus.unstarted) {
    errorMessage = {
      title: t('preview.preview_not_available'),
      text: t('preview.please_wait_document_is_being_processed'),
      icon: 'watch_later',
    };
  }

  return errorMessage;
};

export default usePageListErrorMessage;
