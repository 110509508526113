import React from 'react';
import { useHistory } from 'react-router-dom';
import Folder from 'api/Folder';
import { useDispatch } from 'react-redux';
import { setLoadingOverlay } from 'redux/actions/Page';
import { updateFoldersList } from 'redux/actions/DocumentOrganizer';
import { AssetProperties } from 'types/Assets';

const useAssetListItem = (
  asset: AssetProperties
): {
  fetchAndNavigateTo: (event: React.MouseEvent<Element, MouseEvent>) => Promise<void>;
  assetState: string;
  href: string;
} => {
  const { id, stateId, identifier, city } = asset;
  /** @const {string}, the address of the asset's document organizer */
  const href = `/assets/${id}/folders`;

  /** @const {function} dispatch redux dispatch function */
  const dispatch = useDispatch();

  /** @const {Object} history react router history object */
  const history = useHistory();

  /** @const {string} assetState Asset data-test-status based on asset state */
  const assetState: string = stateId === 2 ? 'in-transaction' : 'in-stock';

  /**
   * @function
   * pre-fetch asset's folder on link click, creating a smooth loading screen
   * @param {string} link the url to navigate to
   * @param {object} event the event object of the onClick
   * @returns {void} Nothing
   */
  const fetchAndNavigateTo = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    try {
      dispatch(setLoadingOverlay(true));
      const resp = await Folder.getFolders({ assetId: id });
      dispatch(updateFoldersList(resp));
      dispatch(setLoadingOverlay(false));
      history.push({ pathname: href, state: { assetName: `${identifier} ${city}` } });
    } catch (e) {
      /**
       * `Folder.getFolders` displays error notification if fetching fails and throws error.
       * if error is thrown, redirect won't happen, thus we need to disable loading spinner.
       */
      dispatch(setLoadingOverlay(false));
    }
  };

  return {
    fetchAndNavigateTo,
    assetState,
    href,
  };
};

export default useAssetListItem;
