import { SessionProperties, UserActionTypes, ACTIONS } from 'types/Session';

const initialState: SessionProperties = {
  id: null,
  user: null,
  trustToken: null,
};

const authenticationReducer = (
  state = initialState,
  action: UserActionTypes
): SessionProperties => {
  switch (action.type) {
    case ACTIONS.LOGIN_USER:
      return action.session;
    case ACTIONS.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default authenticationReducer;
