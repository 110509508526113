/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css as styledComponentsCss, keyframes } from 'styled-components';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  border,
  BorderProps,
} from 'styled-system';
import { css } from '@styled-system/css';

interface SpinnerProps extends LayoutProps, PositionProps, SpaceProps, BorderProps {
  size?: string;
  role?: string;
  className?: string;
}

const rotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const StyledSpinner: React.FC<SpinnerProps> = styled('span')(
  css({
    display: 'inline-block',
    width: '2rem',
    height: '2rem',
    verticalAlign: 'text-bottom',
    border: '.2em solid currentColor',
    borderRadius: '50%',
  }),
  /* Animations can not be properly interpolated with the styled system object syntax.
     They must be inside styled-components css function using the tagged template syntax */
  styledComponentsCss`
    animation: ${rotate} .75s linear infinite;
  `,
  space,
  layout,
  position,
  border,
  {
    // Override borderColor when set by component
    borderRightColor: 'transparent',
  }
);

const Spinner: React.FC<SpinnerProps> = ({ ...props }) => {
  const { size = 'sm', role = 'status', ...rest } = props;
  return <StyledSpinner {...rest} size={size} role={role} aria-hidden="true" />;
};

export default Spinner;
