export enum ACTIONS {
  SET_FILTER = 'SET_FILTER',
}

export enum FILTERS {
  ASSETS = 'ASSETS',
  DOCUMENTS = 'DOCUMENTS',
}

export type FilterProperties = {
  text?: string;
};

export interface SetFilter {
  type: ACTIONS.SET_FILTER;
  filter: Record<string, FilterProperties>;
}

export interface FilterRedux {
  [key: string]: FilterProperties;
}
