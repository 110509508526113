/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { color, typography, space, variant, layout, LayoutProps } from 'styled-system';
import { css } from '@styled-system/css';
import Text, { BaseTextProps } from '../Text';

interface HeadingProps extends BaseTextProps, LayoutProps {
  size?: string;
}

const StyledHeading: React.FC<HeadingProps> = styled(Text)(
  css({
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'bold',
    color: 'text',
  }),
  variant({
    prop: 'truncate',
    variants: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }),
  color,
  typography,
  space,
  layout
);

StyledHeading.defaultProps = {
  fontSize: 'md',
  as: 'h1',
};

const Heading: React.FC<HeadingProps> = ({ children, ...props }) => (
  <StyledHeading {...props}>{children}</StyledHeading>
);

export default Heading;
