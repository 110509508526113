import React from 'react';
import { Box, Img } from 'primitives';

interface AssetImageProps {
  src?: string;
  alt?: string;
}

const AssetImage: React.FC<AssetImageProps> = ({ src, alt }) => (
  <Box paddingTop="66.66%" overflow="hidden" position="relative">
    <Box
      position="absolute"
      left="0"
      right="0"
      top="0"
      bottom="0"
      margin="auto"
      backgroundColor="grayDark"
    >
      {src && <Img size="100%" overflow="hidden" src={src} alt={alt} />}
    </Box>
  </Box>
);

export default AssetImage;
