import React from 'react';
import { useTranslation } from 'react-i18next';
import { AssetProperties } from 'types/Assets';
import styled, { css as styledComponentsCss, keyframes } from 'styled-components';
import { Box, Flex, Text, Card, Link } from 'primitives';
import AssetImage from './AssetImage';
import useAssetListItem from './AssetListItem.hook';

const fadeIn = keyframes`
  from {
    opacity: 60%;
  }
`;

const StyledAssetListItemPlaceholder = styled(Card)`
  animation: ${styledComponentsCss`${fadeIn} 0.6s infinite alternate;`};
`;

const AssetListItem: React.FC<{ asset: AssetProperties }> = ({ asset }) => {
  const { t } = useTranslation('countries');
  const { iconFile, identifier, city, street, country, stateId } = asset;
  const { assetState, fetchAndNavigateTo, href } = useAssetListItem(asset);

  return (
    <Card size="100%" data-test-id="asset-tile" data-test-status={assetState}>
      <Link to={href} onClick={fetchAndNavigateTo} block>
        <Flex flexDirection={['row', null, 'column']}>
          <Box width={[1 / 4, null, 1]}>
            <AssetImage src={iconFile} alt={`${identifier} ${city}`} />
          </Box>
          <Flex
            width={[3 / 4, null, 1]}
            alignItems="center"
            data-test-id="details"
            flexDirection={['row', null, 'column']}
          >
            <Flex
              px="3"
              width={['calc(100% - 10px)', null, '100%']}
              flexDirection="column"
              flex="1"
            >
              <Text data-test-id="name" fontWeight="bold" mt={[0, null, 3]} truncate>
                {`${identifier} ${city}`}
              </Text>
              <Text data-test-id="address" fontSize="xs" mb={[0, null, 3]} truncate>
                {`${street}, ${t(country)}`}
              </Text>
            </Flex>
            <Box
              width={['10px', null, '100%']}
              height={['100%', null, '10px']}
              backgroundColor={stateId === 2 ? 'blueLight' : 'transparent'}
              data-test-id="transaction-flag"
            />
          </Flex>
        </Flex>
      </Link>
    </Card>
  );
};

export const AssetListItemPlaceholder: React.FC = () => (
  <StyledAssetListItemPlaceholder size="100%" data-test-id="asset-tile" data-test-status="loading">
    <Flex flexDirection={['row', null, 'column']} size="100%">
      <Box width={[1 / 4, null, 1]} backgroundColor="gray">
        <AssetImage />
      </Box>
      <Flex
        width={[3 / 4, null, 1]}
        alignItems="center"
        data-test-id="details"
        flexDirection={['row', null, 'column']}
      >
        <Flex px="3" width="100%" flexDirection="column" flex="1">
          <Text fontWeight="bold" mt={[0, null, 3]} mb={2} pb={3} backgroundColor="grayLight" />
          <Text fontSize="xs" pb={2} mb={[0, null, 3]} backgroundColor="grayLight" />
        </Flex>
      </Flex>
    </Flex>
  </StyledAssetListItemPlaceholder>
);

export default AssetListItem;
