import { DefaultTheme } from 'styled-components';
import { transparentize, lighten, darken, desaturate } from 'polished';

const palette = {
  white: '#FFFFFF',
  black: '#000000',
  gray: '#C4D2E3',
  grayLight: '#F4F6F8',
  grayDark: '#758490',
  grayDark2: '#212B36',
  blue: '#102E50',
  blueLight: '#00BBFB',
  blueLight2: '#66D6FD',
  blueLight3: '#99E4FD',
  blueDark: '#006E93',
  blueDark2: '#034257',
  blueDark3: '#000E1C',
  red: '#9b2056',
  green: '#08BE67',
  yellow: '#ffc107',
};

const notifications = {
  success: {
    background: lighten(0.55, palette.green),
    text: darken(0.2, palette.green),
    top: darken(0.2, palette.green),
  },
  error: {
    background: lighten(0.4, desaturate(0.6, palette.red)),
    text: darken(0.2, palette.red),
    top: palette.red,
  },
  warning: {
    background: lighten(0.4, palette.yellow),
    text: darken(0.3, palette.yellow),
    top: darken(0.25, palette.yellow),
  },
};

const screenSizes = {
  xs: 322,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1140,
  max: 1400,
};

const fontSizes = {
  xxs: '0.5em',
  xs: '0.75em',
  sm: '0.875em',
  md: '1em',
  lg: '1.25em',
  xl: '1.5em',
  xxl: '1.75em',
};

const theme: DefaultTheme = {
  space: [
    0,
    '0.25rem',
    '0.5rem',
    '1rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3.5rem',
    '4rem',
    '4.5rem',
    '5rem',
  ],
  sizes: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    xxl: '1.75rem',
    container: `${screenSizes.max}px`,
  },
  fontSizes,
  fonts: {
    body: 'proxima-nova, Helvetica, Arial, sans-serif',
    heading: 'proxima-nova, Helvetica, Arial, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    base: 1.5,
    heading: 1.2,
    ...fontSizes,
  },
  screenSizes,
  breakpoints: [
    `${screenSizes.xs}px`,
    `${screenSizes.sm}px`,
    `${screenSizes.md}px`,
    `${screenSizes.lg}px`,
    `${screenSizes.xl}px`,
  ],
  transition: {
    default: 'all 0.2s ease-in-out',
    transform: 'transform 0.2s ease-in-out',
    timing: '0.2s',
    easing: 'ease-in-out',
  },
  colors: {
    ...palette,
    notifications,
    primary: palette.blueLight,
    secondary: palette.gray,
    muted: lighten(0.2, palette.grayDark),
    text: palette.grayDark,
    hovered: palette.blueDark3,
    background: palette.grayLight,
    warning: palette.yellow,
    loadingSpinnerColor: palette.blueDark,
    loadingBackgroundColor: transparentize(0.3, palette.white),

    // Inputs
    inputColor: palette.grayDark,
    inputBackgroundColor: palette.white,
    inputBackgroundColorFocus: palette.grayLight,
    inputPlaceholderColor: palette.grayDark,
  },
  borderRadius: {
    default: '0.25rem',
  },
  shadows: {
    default: `0 0 0 1px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.15)`,
  },
};

export const inverted: DefaultTheme = {
  ...theme,
  colors: {
    ...palette,
    primary: palette.blueLight,
    secondary: palette.white,
    muted: transparentize(0.5, palette.white),
    text: palette.white,
    hovered: transparentize(0.2, palette.white),
    background: palette.blueDark3,
    warning: palette.yellow,
    loadingSpinnerColor: palette.blueLight,
    loadingBackgroundColor: transparentize(0.3, palette.blueDark3),

    // Inputs
    inputBackgroundColor: transparentize(0.8, palette.blueLight),
    inputBackgroundColorFocus: transparentize(0.7, palette.blueLight),
    inputColor: palette.white,
    inputPlaceholderColor: transparentize(0.5, palette.white),
  },
};

export default theme;
