import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box } from 'primitives';

interface DisplayPageNumberProps {
  pageNumber: number;
  pageCount: number;
}

const DisplayPageNumber: React.FC<DisplayPageNumberProps> = ({ pageNumber, pageCount }) => {
  const { t } = useTranslation('document-organizer');
  return (
    <Box
      backgroundColor="grayDark2"
      borderRadius="1em"
      display="inline-block"
      px="0.6em"
      py="0.3em"
      lineHeight="xxs"
      boxShadow="default"
    >
      <Text as="span" color="white" fontSize="xs" lineHeight="xs">
        {t('page-number', { pageNumber: pageNumber + 1, pageCount })}
      </Text>
    </Box>
  );
};

export default DisplayPageNumber;
