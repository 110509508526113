import { ReduxStates } from 'types/ReduxProps';
import { FilterRedux, FILTERS } from 'types/Filter';

export const getFilters = ({ filter }: ReduxStates): FilterRedux => {
  return filter;
};

export const getFilterText = (namespace: FILTERS) => ({
  filter,
}: ReduxStates): string | undefined => filter[namespace].text;

export default getFilters;
