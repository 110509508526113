/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { flexbox, layout, grid, variant } from 'styled-system';
import { css } from '@styled-system/css';
import Flex, { BaseFlexProps } from '../Flex';

interface ContainerProps extends BaseFlexProps {
  align?: string;
}

const StyledContainer: React.FC<BaseFlexProps> = styled(Flex)(
  css({
    width: '100%',
    maxWidth: 'container',
    margin: '0 auto',
  }),
  variant({
    prop: 'fullWidth',
    variants: {
      true: {
        maxWidth: '100%',
      },
    },
  }),
  flexbox,
  layout,
  grid
);

StyledContainer.defaultProps = {
  flexDirection: 'column',
  flex: '1',
};

const Container: React.FC<ContainerProps> = ({ children, ...props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
);

export default Container;
