/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box, { BaseBoxProps } from 'primitives/Box';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import {
  space,
  color,
  border,
  layout,
  grid,
  flexbox,
  FlexboxProps,
  FlexProps,
  FlexDirectionProps,
  AlignContentProps,
  AlignItemsProps,
  JustifyContentProps,
  JustifyItemsProps,
} from 'styled-system';

export interface FlexBoxProps
  extends BaseBoxProps,
    FlexProps,
    FlexboxProps,
    AlignContentProps,
    AlignItemsProps,
    FlexDirectionProps,
    JustifyContentProps,
    JustifyItemsProps {}

const StyledFlex: React.FC<FlexBoxProps> = styled(Box)(
  css({
    display: 'flex',
    // Bugfix for safari on ios to properly calculate dynamic height
    flexShrink: 0,
  }),
  flexbox,
  space,
  color,
  border,
  layout,
  grid
);

const Flex: React.FC<FlexBoxProps> = ({ children, ...props }) => (
  <StyledFlex {...props}>{children}</StyledFlex>
);

export default Flex;
