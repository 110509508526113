import React, { memo, Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import { MFC } from 'types/React';
import { FolderProperties } from 'types/Folders';
import { DocumentListItemProperties } from 'types/Documents';
import FolderListItem from 'components/FolderListItem';
import DocumentListItem from 'components/DocumentListItem';
import ListItemPlaceholder from './ListItemPlaceholder';

interface ListRendererProps {
  assetId?: number;
  folderId?: number;
  list: Array<FolderProperties | DocumentListItemProperties>;
  type: ListType;
}

export enum ListType {
  folder,
  document,
}

const ListRenderer: MFC<ListRendererProps> = ({ list, assetId, folderId, type }) => (
  <>
    {list.map(
      (item: FolderProperties | DocumentListItemProperties): JSX.Element => (
        <Fragment key={item.id + item.updated}>
          {type === ListType.folder && (
            <LazyLoad
              once
              resize
              placeholder={<ListItemPlaceholder icon="folder" />}
              scrollContainer=".main-content"
            >
              <FolderListItem folder={item as FolderProperties} assetId={assetId} />
            </LazyLoad>
          )}
          {type === ListType.document && (
            <LazyLoad
              once
              resize
              placeholder={<ListItemPlaceholder icon="insert_drive_file" />}
              scrollContainer=".main-content"
            >
              <DocumentListItem
                document={item as DocumentListItemProperties}
                assetId={assetId}
                folderId={folderId}
              />
            </LazyLoad>
          )}
        </Fragment>
      )
    )}
  </>
);

export default memo(ListRenderer);
ListRenderer.whyDidYouRender = true;
