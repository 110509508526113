import React from 'react';
import { Spinner, Flex } from 'primitives';
import { useSelector } from 'react-redux';
import { getLoadingOverlay } from 'redux/mappers/Page';

interface LoadingProps {
  page?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ page = false }) => (
  <Flex
    position={page ? 'fixed' : 'absolute'}
    top="0"
    left="0"
    right="0"
    bottom="0"
    display="flex"
    justifyContent="center"
    alignItems="center"
    backgroundColor={page ? 'grayLight' : 'loadingBackgroundColor'}
  >
    <Spinner borderColor="loadingSpinnerColor" borderWidth=".25em" size="2rem" />
  </Flex>
);

export const LoadingOverlay: React.FC = () => {
  const isLoading = useSelector(getLoadingOverlay);

  // double negation makes sure `isLoading` isn't printed as `0` value
  return <>{!!isLoading && <Loading />}</>;
};

export default Loading;
