import { SetFilter, FilterProperties, ACTIONS } from 'types/Filter';

export const setFilter = (filter: Record<string, FilterProperties>): SetFilter => {
  return {
    type: ACTIONS.SET_FILTER,
    filter,
  };
};

export default setFilter;
