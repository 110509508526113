import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MFC } from 'types/React';
import AssetAPI from 'api/Asset';
import { changeHeaderTitle, changePageTitle } from 'redux/actions/Page';
import Footer from 'components/Footer';
import FolderAndDocumentList from 'components/FolderAndDocumentList';
import Breadcrumbs from 'components/Breadcrumbs';
import { useTranslation } from 'react-i18next';

interface HistoryState {
  assetName: string;
}

const DocumentOrganizer: MFC = () => {
  /** @const {function} dispatch redux dispatch function */
  const dispatch = useDispatch();

  const [pageTitle, setPageTitle] = useState('');

  /**
   * @const {string} assetId asset id passed in url
   * @const {string} folderId folder id passed in url
   */
  const { assetId, folderId } = useParams<{ assetId: string; folderId?: string }>();

  /** @const {Object} location react router location object */
  const location = useLocation<HistoryState>();

  /** @const {Object} state browser history state */
  const {
    state = {
      assetName: '',
    },
  } = location;

  const [assetInfo] = useState(state);

  const { t } = useTranslation('document-organizer');

  useEffect(() => {
    dispatch(changePageTitle(t('title')));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(changeHeaderTitle(pageTitle));
  }, [dispatch, pageTitle, t]);

  useEffect(() => {
    let isSubscribed = true;
    /** @const {function} fetchAssetInfo async function to fetch and populate asset name in header */
    const fetchAssetInfo = async (): Promise<void> => {
      try {
        const assetInfoAPIResponse = await AssetAPI.getAsset(Number(assetId));
        if (isSubscribed)
          setPageTitle(`${assetInfoAPIResponse.identifier} ${assetInfoAPIResponse.city}`);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    // fetch asset info if name isn't passed as prop
    if (!assetInfo.assetName) {
      fetchAssetInfo();
    } else {
      setPageTitle(assetInfo.assetName);
    }
    // clean up when the component un-mounts
    return (): void => {
      isSubscribed = false;
    };
  }, [dispatch, assetId, assetInfo]);

  return (
    <>
      <Breadcrumbs />
      <FolderAndDocumentList assetId={Number(assetId)} folderId={Number(folderId)} />
      <Footer />
    </>
  );
};

export default React.memo(DocumentOrganizer);

// track component's unnecessary re-renders
DocumentOrganizer.whyDidYouRender = true;
