import React from 'react';
import { Switch as ReactSwitch, Route } from 'react-router-dom';
import PageNotFoundRouter from 'routers/routes/PageNotFoundRouter';

/**
 * extends the react-router-dom switch
 * by providing a 404 page not found route
 * if all routes failed
 */
export const Switch: React.FC = ({ children }) => (
  <ReactSwitch>
    {children}
    <Route path="*" component={PageNotFoundRouter} />
  </ReactSwitch>
);

enum Routes {
  Root = '/',
  Cockpit = '/cockpit',
  LoginPage = '/login',
  ForgotPassword = '/forgotpassword',
  ForgotPasswordConfirmation = '/forgotpassword/confirmation',
  Assets = '/assets',
  UserRecoverPassword = '/user/password/:userId/:invitationHash',
  DocumentOrganizer = '/assets/:assetId/folders/:folderId?',
  DocumentPreview = '/assets/:assetId/folders/:folderId/documents/:documentId',
}

export default Routes;
