/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  border,
  BorderProps,
  variant,
  layout,
  LayoutProps,
} from 'styled-system';
import { css } from '@styled-system/css';

export interface InputProps extends SpaceProps, ColorProps, BorderProps, LayoutProps {
  className?: string;
  name?: string;
  block?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string | number | string[] | null | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  id?: string;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  autoComplete?: string;
  autoFocus?: boolean;
  variant?: 'transparent';
}

const StyledInput: React.FC<InputProps> = styled('input')(
  css({
    fontSize: 'md',
    paddingX: '3',
    paddingY: '2',
    borderRadius: '0.25rem',
    transition: 'default',
    border: 'none',
    backgroundColor: 'inputBackgroundColor',
    color: 'inputColor',
    outline: 'none',
    '&::placeholder': {
      color: 'inputPlaceholderColor',
    },
    '&:focus': {
      boxShadow: 'none',
      outline: 'none',
      backgroundColor: 'inputBackgroundColorFocus',
    },
    '&:disabled, &:[readonly]': {
      pointerEvents: 'none',
      backgroundColor: 'muted',
    },
  }),
  variant({
    variants: {
      transparent: {
        backgroundColor: 'transparent',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&::placeholder': {
          color: 'muted',
        },
      },
    },
  }),
  variant({
    prop: 'block',
    variants: {
      true: {
        display: 'block',
        width: '100%',
      },
    },
  }),
  space,
  color,
  border,
  layout
);

StyledInput.defaultProps = {
  type: 'text',
  placeholder: '',
  autoComplete: '',
};

// It's not possible to access 'ref' from a functional component.
// We need to use forwardRef to do so. https://reactjs.org/docs/forwarding-refs.html
const Input: React.FC<InputProps> = React.forwardRef(function Input(props, ref) {
  return <StyledInput ref={ref} {...props} />;
});

export default Input;
