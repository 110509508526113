export type UserProperties = {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  title: string;
  email: string;
  lang: string;
  loginDate: number;
  lastLoginDate: number;
  features: string[];
  sso: boolean;
  mfa: string | null;
  created: number;
  updated: number;
  state: number;
  notificationSettings: {
    qa: string | null;
    dqa: string | null;
    newDocument: string | null;
  };
  currentRole: string;
  roles: string[];
};

export interface SessionProperties {
  id: string | null;
  user: UserProperties | null;
  trustToken: string | null;
}

export interface GetSessionParamsAPI {
  email: string;
  password: string;
}

export interface GetResetRequest {
  disclaimer: string | null;
  email: string;
}

export type PostResetPasswordLinkParams = { userId: string; invitationHash: string };

export type PostResetPasswordNewPasswordParams = {
  password: string;
  passwordRepeat: string;
};

// redux
export enum ACTIONS {
  LOGIN_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER',
}
export interface UserLoginAction {
  type: ACTIONS.LOGIN_USER;
  session: SessionProperties;
}

export interface UserLogoutAction {
  type: ACTIONS.LOGOUT_USER;
}

export type UserActionTypes = UserLoginAction | UserLogoutAction;
