import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MFC } from 'types/React';
import { Button, Img, Box } from 'primitives';
import Loading from 'components/Loading';
import ComponentErrorMessage from 'components/ComponentErrorMessage';
import DisplayPageNumber from 'components/DisplayPageNumber';

interface Props {
  id: number;
  pageNumber: number;
  pageCount: number;
}

const PageLoader: MFC<Props> = ({ id, pageNumber, pageCount }) => {
  const { t } = useTranslation('document-organizer');
  const [isLoading, setIsLoading] = useState(true);
  const [isCurrent, setIsCurrent] = useState(false);
  const [isError, setIsError] = useState(false);
  const imgPath = `/api/documents/${id}/preview/${pageNumber}`;

  return (
    <Button
      data-test-id="page-loader--button"
      variant="transparent"
      p={0}
      pb={isLoading || isError ? '140%' : 0}
      width="100%"
      backgroundColor={isLoading ? 'gray' : 'white'}
      onClick={(): void => setIsCurrent(!isCurrent)}
      disabled={isLoading || isError}
      borderRadius={0}
      size="100%"
      display="block"
      position="relative"
    >
      {isLoading && <Loading />}
      <Img
        width="100%"
        height="auto"
        data-test-id="page-loader--image"
        display={isError ? 'none' : 'block'}
        src={imgPath}
        onLoad={(): void => setIsLoading(false)}
        onError={(): void => {
          setIsLoading(false);
          setIsError(true);
        }}
      />
      <Box
        data-test-id="page-loader--current"
        position="fixed"
        top="0"
        right="0"
        bottom="0"
        left="0"
        zIndex={10}
        height="100%"
        width="100%"
        overflow="scroll"
        display={isCurrent ? 'block' : 'none'}
        backgroundColor="grayLight"
      >
        <Box m={5} boxShadow="default" display="inline-block">
          <Img width="initial" maxWidth="initial" height="initial" src={imgPath} />
        </Box>
      </Box>
      {!isLoading && (
        <Box position="absolute" left="1rem" bottom="1rem" opacity={isError ? 0.5 : 1}>
          <DisplayPageNumber pageNumber={pageNumber} pageCount={pageCount} />
        </Box>
      )}
      {isError && (
        <Box position="absolute" top="0" right="0" bottom="0" left="0">
          <ComponentErrorMessage title={t('preview.preview_not_available')} icon="broken_image" />
        </Box>
      )}
    </Button>
  );
};

export const PagePlaceholder: React.FC = () => {
  return (
    <Box
      data-test-id="page-loader--loading"
      pb="140%"
      backgroundColor="gray"
      width="100%"
      position="relative"
    >
      <Loading />
    </Box>
  );
};

export default PageLoader;
PageLoader.whyDidYouRender = true;
