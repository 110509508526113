export enum ACTIONS {
  HEADER_TITLE = 'HEADER_TITLE',
  PAGE_TITLE = 'PAGE_TITLE',
  LOADING_STATE = 'LOADING_STATE',
  SSO_PROVIDERS = 'SSO_PROVIDERS',
}

export interface PageRedux {
  loadingState: boolean;
  pageTitle: string;
  headerTitle: string;
  SSOProviders: Array<string>;
}

export interface UpdateTitle {
  type: ACTIONS.HEADER_TITLE | ACTIONS.PAGE_TITLE;
  title: string;
}

export interface SetOverlay {
  type: ACTIONS.LOADING_STATE;
  loadingState: boolean;
}

export interface SetSSOProviders {
  type: ACTIONS.SSO_PROVIDERS;
  providers: Array<string>;
}

export type PageActionType = UpdateTitle | SetOverlay | SetSSOProviders;
