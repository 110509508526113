import React from 'react';
import { useTranslation } from 'react-i18next';
import ReduxDocumentOrganizer from 'redux/mappers/DocumentOrganizer';
import { useSelector } from 'react-redux';
import Truncate from 'helpers/Truncate';
import { Link, Container, Flex, Icon } from 'primitives';

const BreadCrumbs: React.FC = () => {
  const { breadcrumbs } = useSelector(ReduxDocumentOrganizer);
  const { t } = useTranslation('document-organizer');
  const getLinkText = (): string => {
    if (!breadcrumbs.currentFolderName) {
      return t('common:loading');
    }
    return breadcrumbs.parentFolderId
      ? breadcrumbs.currentFolderName
      : t('breadcrumbs.root_folder');
  };
  const linkUrl: string = breadcrumbs.parentFolderId
    ? `/assets/${breadcrumbs.assetId}/folders/${breadcrumbs.parentFolderId}`
    : '/cockpit';

  return (
    <Container
      data-test-id="breadcrumbs"
      flexDirection="row"
      alignItems="center"
      flex="0 0 auto"
      p="3"
      color="grayDark"
    >
      <Link data-test-id="back-link" to={linkUrl} pr="3">
        <Icon verticalAlign="middle" icon="arrow_back" />
      </Link>
      <Flex
        data-test-id="current-folder"
        pl="3"
        flex="1"
        borderLeft="1px solid"
        borderColor="gray"
        overflow="hidden"
      >
        <Truncate text={getLinkText()} />
      </Flex>
    </Container>
  );
};

export default BreadCrumbs;
