import { ReduxStates } from 'types/ReduxProps';
import { SessionProperties } from 'types/Session';

/**
 * @constant {Boolean} isLoggedIn
 * return login status
 * this is better for performance as it enables react to directly asses if the prop has changed
 * as react shallow compare changes before re-rendering, thus object comparison is always false
 */
export const isLoggedIn = ({ session }: ReduxStates): boolean => Boolean(session.id);

// return all Session Props
const getSessionProps = ({ session }: ReduxStates): SessionProperties => session;

export default getSessionProps;
