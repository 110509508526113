export enum ACTIONS {
  SET_PAGE = 'SET_PAGE',
}

export interface SetPage {
  type: ACTIONS.SET_PAGE;
  page: number;
}

export interface PaginationProperties {
  page: number | undefined;
}
