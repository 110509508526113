import React from 'react';
import { FolderProperties } from 'types/Folders';
import Truncate from 'helpers/Truncate';
import { Link, Box, Icon, Container } from 'primitives';
import styled from 'styled-components';
import { css } from '@styled-system/css';

interface Props {
  assetId?: number;
  folder: FolderProperties;
}

const ListItemLink = styled(Link)(
  css({
    alignItems: 'center',
    display: 'flex',
    px: '3',
    height: '3.5rem',
    transition: 'default',
    /**
     * TODO: figure out a way to reference with styled-system just like styled-components
     * https://styled-components.com/docs/advanced#referring-to-other-components
     */
    '.icon': {
      transition: 'default',
    },
    '&:hover .icon': {
      color: 'blueDark',
    },
  })
);

const FolderListItem: React.FC<Props> = ({ folder, assetId }) => {
  const href = `/assets/${assetId}/folders/${folder.id}`;

  return (
    <Box borderBottom="1px solid" borderColor="grayLight" data-test-id="folder">
      <Container>
        <ListItemLink to={href} fontWeight={folder.documentCount ? 'bold' : undefined}>
          <Icon icon="folder" color="primary" className="icon" pr="3" />
          <Box flex="1" overflowX="hidden">
            <Truncate text={`${folder.number} ${folder.name}`} />
          </Box>
          <Icon icon="keyboard_arrow_right" color="gray" />
        </ListItemLink>
      </Container>
    </Box>
  );
};

export default FolderListItem;
