import { FolderProperties } from './Folders';
import { AssetProperties } from './Assets';

export type DocumentListItemProperties = {
  id: number;
  name: string;
  updated: number;
};

export type DocumentProperties = {
  id: number;
  name: string;
  updated: number;
  pageCount: number;
  previewStatus: PreviewStatus;
  asset: Partial<AssetProperties>;
  folder: Partial<FolderProperties>;
};

export enum PreviewStatus {
  unstarted = 0,
  generated = 1,
  progress = 2,
  impossible = 3,
  error = 4,
}

export interface GetDocumentsParamsAPI {
  assetId: number;
  folderId: number;
}

export default PreviewStatus;
