import { UpdateTitle, SetSSOProviders, SetOverlay, ACTIONS } from 'types/Page';

/** changed the title in the header only */
export const changeHeaderTitle = (title: string): UpdateTitle => ({
  type: ACTIONS.HEADER_TITLE,
  title,
});

/** change the page title */
export const changePageTitle = (title: string): UpdateTitle => {
  document.title = `Architrave - ${title}`;
  return { type: ACTIONS.PAGE_TITLE, title };
};

export const setLoadingOverlay = (loadingState: boolean): SetOverlay => ({
  type: ACTIONS.LOADING_STATE,
  loadingState,
});

export const setSSOProviders = (providers: Array<string>): SetSSOProviders => ({
  type: ACTIONS.SSO_PROVIDERS,
  providers,
});
