import {
  DocumentListItemProperties,
  DocumentProperties,
  GetDocumentsParamsAPI,
} from 'types/Documents';
import { NotificationTypes } from 'types/Notifications';
import Request from './API';

class Document {
  static getFolderDocuments = (
    params: GetDocumentsParamsAPI
  ): Promise<Array<DocumentListItemProperties>> => {
    const request = new Request();
    request.setNotification('default', {
      type: NotificationTypes.error,
      title: 'error.generic.title',
      text: 'api.documents.fetch_failed.text',
    });
    /** ignore error on permission for documents as it is by design */
    request.silenceNotification('403');
    return request.get(`/api/assets/${params.assetId}/folder/${params.folderId}/documents`);
  };

  static getDocument = async (documentId: number): Promise<DocumentProperties> => {
    const request = new Request();
    request.throwError = true;
    request.setNotification('default', {
      type: NotificationTypes.error,
      title: 'error.generic.title',
      text: 'api.documents.page.fetch_failed.text',
    });
    return request.get(`/api/document/${documentId}`);
  };
}

export default Document;
