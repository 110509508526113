import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeHeaderTitle, changePageTitle } from 'redux/actions/Page';
import Document from 'api/Document';
import { DocumentProperties } from 'types/Documents';
import { NotificationTypes } from 'types/Notifications';
import NotificationManager from 'helpers/NotificationManager';

import { CATEGORIES, ACTIONS } from 'types/Matomo';

const useDocumentPreview = (): {
  document: DocumentProperties | undefined;
} => {
  const { t } = useTranslation('document-organizer');
  const { assetId, folderId, documentId = '' } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [document, setDocument] = useState<DocumentProperties>();

  // Fetch document and save it to state
  useEffect(() => {
    let isSubscribed = true;
    dispatch(changePageTitle(t('preview.title')));
    const fetchDocuments = async (): Promise<void> => {
      try {
        const response = await Document.getDocument(Number(documentId));
        const pageTitle = `${response.asset.identifier} ${response.asset.city}`;
        if (isSubscribed) {
          setDocument(response);
          dispatch(changeHeaderTitle(pageTitle));
        }
        const fileExtension = (): string => {
          const nameParts = response.name.split('.');
          const extension = nameParts.length > 1 ? (nameParts.pop() as string) : '';
          return extension.toLowerCase();
        };
      } catch (error) {
        history.push(assetId && folderId ? `/assets/${assetId}/folders/${folderId}` : 'cockpit');
      }
    };
    fetchDocuments();

    // clean up when the component dismounts
    return (): void => {
      isSubscribed = false;
    };
    /**
     * disable react-hooks/exhaustive-deps as adding ['dispatch', 'history']
     * will cause extra rerenders on unit tests, due to them being functions
     */
    // eslint-disable-next-line
  }, [documentId, assetId, folderId]);

  // Handle redirection if url does not match document data
  useEffect(() => {
    if (!document) return;
    const wrongAsset = Number(assetId) !== document.asset.id;
    const wrongFolder = Number(folderId) !== document.folder.id;

    if (wrongAsset || wrongFolder) {
      // Redirect to cockpit or to root folder
      history.push(wrongAsset ? 'cockpit' : `/assets/${assetId}/folders`);

      NotificationManager.addNotification({
        type: NotificationTypes.error,
        title: 'page_not_found',
        text: 'you_have_been_redirected',
      });
    }
  }, [document, assetId, folderId, history]);

  return { document };
};

export default useDocumentPreview;
