import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from 'routers/MainRouter';
import { MFC } from 'types/React';

/**
 * This dependency is only available on dev, it identifies useless rerender issues in console
 * How to use:
 * 1- import MFC (Monitored Functional Component) type, `import { MFC } from 'types/React'`
 * 2- add `whyDidYouRender` parameter to the react function
 */
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const App: MFC = () => (
  <BrowserRouter basename="/m">
    <MainRouter />
  </BrowserRouter>
);

export default App;

// track component's unnecessary re-renders
App.whyDidYouRender = true;
