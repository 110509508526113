import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Truncate from 'helpers/Truncate';
import { DocumentListItemProperties } from 'types/Documents';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { Link, Box, Icon, Text, Container } from 'primitives';

interface Props {
  assetId?: number;
  folderId?: number;
  document: DocumentListItemProperties;
}

const ListItemLink = styled(Link)(
  css({
    alignItems: 'center',
    display: 'flex',
    px: '3',
    height: '3.5rem',
    transition: 'default',
    /**
     * TODO: figure out a way to reference with styled-system just like styled-components
     * https://styled-components.com/docs/advanced#referring-to-other-components
     */
    '.icon': {
      transition: 'default',
    },
    '&:hover .icon': {
      filter: 'brightness(70%)',
    },
  })
);

const DocumentListItem: React.FC<Props> = ({ document, assetId, folderId }) => {
  const { t } = useTranslation('document-organizer');
  const href = `/assets/${assetId}/folders/${folderId}/documents/${document.id}`;
  const displayDate = useCallback(
    () => (
      <>
        {`${t('document.uploaded_at')} `}
        <Moment format="DD MMM YYYY" unix>
          {document.updated}
        </Moment>
      </>
    ),
    [document.updated, t]
  );
  const extension = document.name ? document.name.split(/[. ]+/).pop() : '';

  return (
    <Box borderBottom="1px solid" borderColor="grayLight" data-test-id="document">
      <Container>
        <ListItemLink to={href} data-test-id="file-link">
          <Icon icon="insert_drive_file" color="green" className="icon" pr="3" />
          <Box flex="1" overflow="hidden">
            <Truncate text={document.name} data-test-id="file-name" />
            <Box fontSize="xs" display="flex">
              <Box pr="2" borderRight="1px solid" borderColor="gray">
                <Text
                  lineHeight="sm"
                  mb="0"
                  textTransform="uppercase"
                  color="muted"
                  data-test-id="file-extention"
                >
                  {extension}
                </Text>
              </Box>
              <Text lineHeight="sm" mb="0" pl="2" color="muted" data-test-id="file-date" truncate>
                {displayDate()}
              </Text>
            </Box>
          </Box>
        </ListItemLink>
      </Container>
    </Box>
  );
};

export default DocumentListItem;
