import React from 'react';
import { store } from 'react-notifications-component';
import Notification from 'components/notification/Notification';
import { NotificationProperties } from 'types/Notifications';

class NotificationManager {
  static count = 0;

  static notificationIds: string[] = [];

  static addNotification(data: NotificationProperties): void {
    const currentNotificationId = `${data.title}-${data.text}--${this.count}`;

    const duplicatedNotificationId = this.notificationIds.find((id) =>
      id.includes(currentNotificationId.split('--')[0])
    );

    if (duplicatedNotificationId) {
      store.removeNotification(duplicatedNotificationId);
      this.notificationIds = this.notificationIds.filter((el) => el !== duplicatedNotificationId);
    }

    store.addNotification({
      id: currentNotificationId,
      content: <Notification title={data.title} text={data.text} type={data.type} />,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeInUp'],
      animationOut: ['animated', 'fadeOut'],
      waitForAnimation: true,
      timingFunction: 'easeInOut',
      dismiss: {
        duration: 5000,
      },
      onRemoval: () => {
        this.notificationIds = this.notificationIds.filter((el) => el !== currentNotificationId);
      },
    });
    this.notificationIds.push(currentNotificationId);
    this.count += 1;
  }
}

export default NotificationManager;
