import { SetPage, PaginationProperties, ACTIONS } from 'types/Pagination';

const initialState: PaginationProperties = {
  page: undefined,
};

const paginationStateReducer = (state = initialState, action: SetPage): PaginationProperties => {
  switch (action.type) {
    case ACTIONS.SET_PAGE: {
      return { page: action.page };
    }
    default:
      return state;
  }
};

export default paginationStateReducer;
