import React from 'react';
import { MFC } from 'types/React';
import Cockpit from 'pages/cockpit/Cockpit';

import { useTranslation } from 'react-i18next';

const CockpitRouter: MFC = () => {
  const { t } = useTranslation('cockpit');
  return <Cockpit />;
};

export default React.memo(CockpitRouter);

// track component's unnecessary re-renders
CockpitRouter.whyDidYouRender = true;
