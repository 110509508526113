/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { flexbox, space, color, border, layout, grid, variant } from 'styled-system';
import Flex, { BaseFlexProps } from '../Flex';

interface ColProps extends BaseFlexProps {
  align?: string;
}

const StyledCol: React.FC<ColProps> = styled(Flex)(
  {
    flexDirection: 'column',
    flex: '1',
  },
  variant({
    prop: 'align',
    variants: {
      top: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      bottom: {
        alignItems: 'end',
      },
    },
  }),
  flexbox,
  space,
  color,
  border,
  layout,
  grid
);

StyledCol.defaultProps = {
  align: 'top',
};

const Col: React.FC<ColProps> = ({ children, ...props }) => (
  <StyledCol {...props}>{children}</StyledCol>
);

export default Col;
