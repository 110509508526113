import React, { ChangeEvent, FormEvent } from 'react';
import { Input, Button, Icon, Row, Form, Text, Container } from 'primitives';
import { FILTERS } from 'types/Filter';
import { MFC } from 'types/React';
import useFilterInput from './FilterInput.hook';

interface FilterInputProperties {
  nameSpace: FILTERS;
  title?: string;
  placeholder?: string;
  isLoading: boolean;
}

const FilterInput: MFC<FilterInputProperties> = ({ nameSpace, title, placeholder, isLoading }) => {
  const { t, filterText, setFilterText, hasFocus, setHasFocus, clearFilter } = useFilterInput(
    nameSpace
  );

  return (
    <Row backgroundColor={hasFocus ? 'white' : 'inherit'} data-test-id="filter">
      <Container flexDirection="row">
        <Button
          py="0.75rem"
          display="flex"
          width={hasFocus ? 'auto' : '100%'}
          variant="transparent"
          onClick={(): void => setHasFocus(true)}
          data-test-id="filter-button"
        >
          <Icon icon="filter_list" />
          {!hasFocus && (
            <Text as="span" fontWeight="normal" pl="3" color="gray" data-test-id="filter-title">
              {title || t('filter.title')}
            </Text>
          )}
        </Button>
        {hasFocus && (
          <Form
            display="flex"
            flexGrow={1}
            onSubmit={(event: FormEvent): void => event.preventDefault()}
            data-test-id="filter-form"
          >
            <Input
              type="text"
              name="filter"
              id="filter"
              data-test-id="filter-input"
              block
              variant="transparent"
              p={0}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => setFilterText(e.target.value)}
              placeholder={placeholder || t('filter.placeholder')}
              value={filterText}
              autoFocus
            />
            <Button
              py="0.75rem"
              display="flex"
              variant="transparent"
              disabled={isLoading}
              onClick={clearFilter}
              data-test-id="close-button"
            >
              <Icon color={isLoading ? 'gray' : 'text'} icon="clear" />
            </Button>
          </Form>
        )}
      </Container>
    </Row>
  );
};

export default React.memo(FilterInput);

// this is required for unit testing as enzyme doesn't handle state change on memo(ed) components
export { FilterInput as FilterInputComponent };

// track component's unnecessary re-renders
FilterInput.whyDidYouRender = true;
