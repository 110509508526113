import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MFC } from 'types/React';
import Routes, { Switch } from 'helpers/Routes';
import DocumentOrganizer from 'pages/documentOrganizer/DocumentOrganizer';
import DocumentPreview from 'pages/DocumentPreview';

const DocumentOrganizerRouter: MFC = () => {
  const { t } = useTranslation('document-organizer');
  return (
    <Switch>
      <Route exact path={Routes.DocumentOrganizer}>
        <DocumentOrganizer />
      </Route>
      <Route exact path={Routes.DocumentPreview}>
        <DocumentPreview />
      </Route>
    </Switch>
  );
};

export default React.memo(DocumentOrganizerRouter);

// track component's unnecessary re-renders
DocumentOrganizerRouter.whyDidYouRender = true;
