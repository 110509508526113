import { combineReducers } from 'redux';
import session from './Session';
import page from './Page';
import documentOrganizer from './DocumentOrganizer';
import filter from './Filter';
import pagination from './Pagination';

export default combineReducers({
  session,
  page,
  documentOrganizer,
  filter,
  pagination,
});
