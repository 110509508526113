import { NotificationProperties } from './Notifications';

export type ErrorResponse = NotificationProperties | boolean;

export interface RequestOptions {
  method: RequestMethod;
  body?: string;
  headers: { [key: string]: string };
}

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

export type QueryParams = Record<string, string | number | undefined>;

export interface FetchParams {
  url: string;
  params?: QueryParams;
  method: RequestMethod;
  body?: object;
}

export type ErrorResponses = {
  [key: string]: NotificationProperties | boolean;
};
