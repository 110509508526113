/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  FlexProps,
} from 'styled-system';

export interface FormProps
  extends SpaceProps,
    ColorProps,
    BorderProps,
    LayoutProps,
    FlexboxProps,
    FlexProps {
  className?: string | undefined;
  as?: string | undefined;
  onSubmit?: ((event: React.FormEvent<HTMLFormElement>) => void) | null | undefined;
}

const StyledForm: React.FC<FormProps> = styled('form')(
  {
    boxSizing: 'border-box',
  },
  space,
  color,
  border,
  layout,
  flexbox
);

const Form: React.FC<FormProps> = ({ children, ...props }) => (
  <StyledForm {...props}>{children}</StyledForm>
);

export default Form;
