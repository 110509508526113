import { createStore, Store } from 'redux';
import reducer from 'redux/reducers/Base';
import { ReduxStates } from 'types/ReduxProps';

class ReduxStore {
  static store: Store;

  static configureStore = (preloadedState?: ReduxStates): void => {
    ReduxStore.store = createStore(reducer, preloadedState);
  };
}

export default ReduxStore;
