import React from 'react';
import { Flex, Icon, Text } from 'primitives';

interface Props {
  title: string;
  text?: string;
  icon?: string;
}

const ComponentErrorMessage: React.FC<Props> = ({ title, text, icon = 'highlight_off' }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    p={2}
    data-test-id="error-message"
    textAlign="center"
    flex="1"
    height="100%"
  >
    <Icon fontSize="5rem" color="gray" icon={icon} mb={1} />
    <Text fontWeight="bold">{title}</Text>
    {text && <Text>{text}</Text>}
  </Flex>
);

export default ComponentErrorMessage;
