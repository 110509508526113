import React from 'react';
import { Flex, Row, Box, Link } from 'primitives';
import { useTranslation } from 'react-i18next';

const FooterLink: React.FC<{ fontSize?: string; color?: string; to: string }> = ({
  children,
  fontSize = 'sm',
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link textAlign="center" underline py="2" px="3" fontSize={fontSize} {...props}>
    {children}
  </Link>
);

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box as="footer" data-test-id="app-footer">
      <Flex justifyContent="center" flexDirection={['column', 'row']}>
        <FooterLink to="https://www.architrave.de/support">{t('footer.support')}</FooterLink>
        <FooterLink to="https://www.architrave.de/datenschutzerklaerung">
          {t('footer.privacy_policy')}
        </FooterLink>
        <FooterLink to="https://www.architrave.de/impressum">{t('footer.Impressum')}</FooterLink>
      </Flex>
      <Row justifyContent="center" mb={3}>
        <FooterLink fontSize="xs" color="muted" to="https://www.architrave.de">
          {`© ${new Date().getFullYear()} ${t('footer.architrave')}`}
        </FooterLink>
      </Row>
    </Box>
  );
};

export default Footer;
