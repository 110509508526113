import React from 'react';
import MiddleEllipsis from 'react-middle-ellipsis';
import styled from 'styled-components';
import { Box } from 'primitives';
import { MFC } from 'types/React';

interface TruncateProps {
  text: string;
}

const NoWrapBox = styled(Box)({ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' });

const Truncate: MFC<TruncateProps> = ({ text }) => (
  <NoWrapBox>
    <MiddleEllipsis>
      <span>{text}</span>
    </MiddleEllipsis>
  </NoWrapBox>
);

export default React.memo(Truncate);

Truncate.whyDidYouRender = true;
