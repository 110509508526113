import { NotificationTypes } from 'types/Notifications';
import {
  SessionProperties,
  GetSessionParamsAPI,
  PostResetPasswordLinkParams,
  PostResetPasswordNewPasswordParams,
  GetResetRequest,
} from 'types/Session';

import { CATEGORIES, ACTIONS } from 'types/Matomo';
import Request from './API';

class Session {
  static async logIn(credentials: GetSessionParamsAPI): Promise<SessionProperties | undefined> {
    try {
      const request = new Request();
      request.throwError = true;
      request.setNotification(['401', '422'], {
        type: NotificationTypes.error,
        title: 'api.login.wrong_credentials.title',
        text: 'api.login.wrong_credentials.text',
      });
      return await request.post(`/api/session`, credentials);
    } catch (error) {
      return undefined;
    }
  }

  static async logOut(): Promise<boolean> {
    try {
      const request = new Request();
      request.throwError = true;
      await request.delete(`/api/session`);
      return true;
    } catch (error) {
      return false;
    }
  }

  static async isLoggedIn(): Promise<SessionProperties> {
    const request = new Request();
    request.silenceNotification('401');
    return (await request.get(`/api/session`)) as SessionProperties;
  }

  static async forgotPasswordRequest(emailAddress: { email: string }): Promise<boolean> {
    try {
      const request = new Request();
      request.throwError = true;
      request.setNotification('404', {
        type: NotificationTypes.error,
        title: 'api.user.forgot_password.not_found.title',
        text: 'api.user.forgot_password.not_found.text',
      });
      await request.post(`/api/users/recover`, emailAddress);
      return true;
    } catch (error) {
      return false;
    }
  }

  static async validateForgotPasswordRequest({
    userId,
    invitationHash,
  }: PostResetPasswordLinkParams): Promise<GetResetRequest> {
    const request = new Request();
    request.throwError = true;
    request.setNotification(['404', '403'], {
      type: NotificationTypes.error,
      title: 'api.user.reset_password.expired.title',
      text: 'api.user.reset_password.expired.text',
    });

    return request.get(`/api/invitations/${userId}/${invitationHash}`);
  }

  static async resetPassword(
    { userId, invitationHash }: PostResetPasswordLinkParams,
    newPassword: PostResetPasswordNewPasswordParams
  ): Promise<boolean> {
    try {
      const request = new Request();
      request.throwError = true;
      // append disclaimerAccept as backend uses the same endpoint for invitations
      await request.post(`/api/invitations/${userId}/${invitationHash}/confirm`, {
        ...newPassword,
        disclaimerAccept: true,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}

export default Session;
