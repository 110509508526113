import ReduxStore from 'redux/store';
import { setSSOProviders } from 'redux/actions/Page';
import Instance from 'api/Instance';
import Session from 'api/Session';
import { userLogin } from 'redux/actions/Session';

const Initializer = (): Promise<[void, void]> => {
  const { store } = ReduxStore;

  const fetchSSOProviders = Instance.getSSOProviders().then((providersList) => {
    store.dispatch(setSSOProviders(providersList));
  });

  const fetchUser = Session.isLoggedIn().then((user) => {
    store.dispatch(userLogin(user));
  });

  return Promise.all([fetchSSOProviders, fetchUser]);
};

export default Initializer;
