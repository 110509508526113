/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestMethod, RequestOptions, FetchParams, QueryParams, ErrorResponses } from 'types/API';
import NotificationManager from 'helpers/NotificationManager';
import { NotificationTypes, NotificationProperties } from 'types/Notifications';
import { userLogout } from 'redux/actions/Session';
import ReduxStore from 'redux/store';

class Request {
  private headers = {
    'Content-Type': 'application/json',
    'X-Srf-Architrave': 'architrave',
  };

  public errorResponses: ErrorResponses = {
    default: {
      type: NotificationTypes.error,
      title: 'error.generic.title',
      text: 'error.generic.body',
    },
    '401': {
      type: NotificationTypes.error,
      title: 'error.401.title',
      text: 'error.401.body',
    },
  };

  public throwError = false;

  private async fetch({ url, params, method, body }: FetchParams): Promise<any> {
    try {
      const options: RequestOptions = {
        method,
        headers: this.headers,
      };

      const query = params ? this.buildQuery(params) : undefined;

      if (method === RequestMethod.POST && body) options.body = JSON.stringify(body);

      const response = await fetch(query ? [url, query].join('?') : url, options);

      if (!response.ok) throw new Error(response.status.toString());

      // return json response unless no content is provided then return response
      if (response.status === 204) return response;
      return response.json();
    } catch (error) {
      if (error.message === '401') {
        const { store } = ReduxStore;
        store.dispatch(userLogout());
      }

      /* Checking if errorResponses is set exactly to false. Undefined and true values
         are not silenced as on both cases the default notification should still be displayed */
      const isSilenced = this.errorResponses[error.message] === false;

      if (!isSilenced) {
        const displayNotification =
          this.errorResponses[error.message] || this.errorResponses.default;
        NotificationManager.addNotification(displayNotification as NotificationProperties);
      }

      if (this.throwError) {
        throw error;
      }
      return [];
    }
  }

  private buildQuery = (params: QueryParams): string => {
    const query: string[] = [];
    if (!params) return '';
    Object.keys(params).map((param): number | undefined => {
      return params[param] ? query.push(`${param}=${params[param]}`) : undefined;
    });
    return query.join('&');
  };

  public setNotification(errorCode: string | string[], notification: NotificationProperties): void {
    if (Array.isArray(errorCode)) {
      errorCode.forEach((error) => {
        this.errorResponses[error] = notification;
      });
      return;
    }
    this.errorResponses[errorCode] = notification;
  }

  public silenceNotification(errorCode: string): void {
    this.errorResponses[errorCode] = false;
  }

  public get(url: string, params?: QueryParams): Promise<any> {
    return this.fetch({ url, params, method: RequestMethod.GET });
  }

  public post(url: string, body: object): Promise<any> {
    return this.fetch({ url, method: RequestMethod.POST, body });
  }

  public delete(url: string): Promise<any> {
    return this.fetch({ url, method: RequestMethod.DELETE });
  }
}

export default Request;
