import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'redux/mappers/Session';
import MainHeader from 'components/MainHeader';
import { LoadingOverlay } from 'components/Loading';
import { MFC } from 'types/React';
import { Flex } from 'primitives';
import { css } from '@styled-system/css';

interface PrivateRouteProps extends RouteProps {
  name: string;
}

const StyledPage = styled(Flex)(
  css({
    display: 'flex',
    backgroundColor: 'background',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  })
);

const PrivateRoute: MFC<PrivateRouteProps> = ({ component, name = '', path }): JSX.Element => {
  const loggedIn = useSelector(isLoggedIn);
  const Page = component as React.FC;
  return (
    <>
      <StyledPage data-test-id={`${name}--page`}>
        <MainHeader />
        <main data-test-id="main-content" className="main-content">
          <Route
            path={path}
            render={(): JSX.Element => (loggedIn ? <Page /> : <Redirect to="/login" />)}
          />
        </main>
      </StyledPage>
      <LoadingOverlay />
    </>
  );
};

/**
 * @constant {function} shouldNotRerender
 * Since the component receives `Page` as an object, the component re-renders unnecessarily
 * as it cannot assert change using shallow comparison on object.
 * Thus, stringify and compare the two states of props to determine
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldNotRerender = (prevProps: any, nextProps: any): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default React.memo(PrivateRoute, shouldNotRerender);

// track component's unnecessary re-renders
PrivateRoute.whyDidYouRender = true;
