import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Box, Icon, Text, Flex } from 'primitives';
import { NotificationProperties, NotificationTypes } from 'types/Notifications';
import './Notification.scss';

const Notification: React.FC<NotificationProperties> = ({
  title = 'default.title',
  text = 'default.text',
  type = NotificationTypes.warning,
}) => {
  // TODO: delegate translations to the components
  const { t } = useTranslation('notifications');
  const notificationType = `notifications.${NotificationTypes[type]}`;

  return (
    <Card
      backgroundColor={`${notificationType}.background`}
      flexDirection="column"
      data-test-id={`notification--${NotificationTypes[type]}`}
    >
      <Flex backgroundColor={`${notificationType}.top`} height="xs" />
      <Flex flexDirection="row" alignItems="center" p="3">
        <Icon color={`${notificationType}.text`} fontSize="2rem" pr="3" icon="error" />
        <Box>
          <Text
            data-test-id="notification--title"
            color={`${notificationType}.text`}
            fontWeight="bold"
          >
            {t(title)}
          </Text>
          <Text data-test-id="notification--text" color={`${notificationType}.text`}>
            {t(text)}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

export default Notification;
