import { PageRedux, PageActionType, ACTIONS } from 'types/Page';

const initialState: PageRedux = {
  headerTitle: '',
  pageTitle: '',
  loadingState: false,
  SSOProviders: [],
};

const pageStateReducer = (state = initialState, action: PageActionType): PageRedux => {
  switch (action.type) {
    case ACTIONS.HEADER_TITLE:
      return { ...state, headerTitle: action.title };
    case ACTIONS.PAGE_TITLE:
      return { ...state, pageTitle: action.title };
    case ACTIONS.LOADING_STATE:
      return { ...state, loadingState: action.loadingState };
    case ACTIONS.SSO_PROVIDERS:
      return { ...state, SSOProviders: action.providers };
    default:
      return state;
  }
};

export default pageStateReducer;
