/* eslint-disable react/jsx-props-no-spreading */
import Flex, { BaseFlexProps } from 'primitives/Flex';
import styled from 'styled-components';
import { flexbox, space, color, border, layout, grid } from 'styled-system';
import { css } from '@styled-system/css';
import React from 'react';

interface CardProps extends BaseFlexProps {
  name?: string;
}

const StyledCard: React.FC<CardProps> = styled(Flex)(
  css({
    boxShadow: 'default',
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: 'default',
    transition: 'transform',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  }),
  flexbox,
  space,
  color,
  border,
  layout,
  grid
);

const Card: React.FC<CardProps> = ({ children, ...props }) => (
  <StyledCard {...props}>{children}</StyledCard>
);

export default Card;
