import { useState, useEffect, useRef, useCallback } from 'react';
import { setPage } from 'redux/actions/Pagination';
import { useDispatch } from 'react-redux';
import { PaginationProperties } from 'types/Assets';

const usePagination = (
  pagination: PaginationProperties
): {
  filteredPages: number[];
  filteredNextPage?: number;
  filteredPreviousPage?: number;
  activePage: number;
  goToPage: (page: number) => void;
} => {
  const { pages, currentPage } = pagination;
  const filteredPages = useRef<number[]>([1]);
  const [activePage, setActivePage] = useState(currentPage);
  const filteredNextPage = pages[filteredPages.current[filteredPages.current.length - 1]];
  const filteredPreviousPage = pages[filteredPages.current[0] - 2];

  const dispatch = useDispatch();

  const goToPage = (page: number): void => {
    setActivePage(page);
    dispatch(setPage(page));
  };

  const getFilteredPages = useCallback((): number[] => {
    // Number of pages around currentPage that should be dispalyed
    const margin = 2;

    // Current page + {margin} pages on each side
    const filterSize = margin * 2 + 1;

    // No filtering if the total number of pages is smaller than the filterSize
    if (pages.length <= filterSize) {
      return pages;
    }

    // First visible page is {margin} pages behind the current page
    let beginIndex = currentPage - margin <= 1 ? 0 : currentPage - (margin + 1);

    // As the current page is closer to last, filtered pages are aways
    // the last ones according to filterSize
    if (currentPage + margin >= pages.length) {
      beginIndex = pages.length - filterSize;
    }

    const filteredArray = pages.slice(beginIndex, beginIndex + filterSize);

    return filteredArray.length ? filteredArray : [1];
  }, [currentPage, pages]);

  useEffect(() => {
    const result = getFilteredPages();
    filteredPages.current = result;
    setActivePage(currentPage);
  }, [currentPage, pages, getFilteredPages]);

  return {
    filteredPages: filteredPages.current,
    filteredNextPage,
    filteredPreviousPage,
    activePage,
    goToPage,
  };
};

export default usePagination;
