import { NotificationTypes } from 'types/Notifications';
import { FoldersAPIRespGetFolders, FoldersAPIParamsGetFolders } from 'types/Folders';
import Request from './API';

class Folder {
  static getFolders = async ({
    assetId,
    folderId,
  }: FoldersAPIParamsGetFolders): Promise<FoldersAPIRespGetFolders> => {
    const request = new Request();
    request.throwError = true;
    request.setNotification('403', {
      type: NotificationTypes.error,
      title: 'api.folder.asset_has_no_folders.title',
      text: 'api.folder.asset_has_no_folders.text',
    });
    return request.get(`/api/assets/${assetId}/folder${folderId ? `/${folderId}` : ''}`);
  };
}

export default Folder;
