import {
  DocumentOrganizerRedux,
  DocumentOrganizerActionTypes,
  ACTIONS,
} from 'types/DocumentOrganizer';

const initialState: DocumentOrganizerRedux = {
  breadcrumbs: { currentFolderName: undefined, parentFolderId: undefined },
  foldersData: { parent: undefined, name: undefined, number: undefined, folders: [] },
};

const documentOrganizerStateReducer = (
  state = initialState,
  action: DocumentOrganizerActionTypes
): DocumentOrganizerRedux => {
  switch (action.type) {
    case ACTIONS.BREADCRUMB:
      return { ...state, breadcrumbs: action.payload };
    case ACTIONS.FOLDERSLIST:
      return { ...state, foldersData: action.payload };
    case ACTIONS.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default documentOrganizerStateReducer;
