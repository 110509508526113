import React from 'react';
import { Flex } from 'primitives';
import LazyLoad from 'react-lazyload';
import AssetListItem, { AssetListItemPlaceholder } from 'components/AssetListItem';
import { AssetProperties } from 'types/Assets';

const AssetList: React.FC<{ assetList: AssetProperties[] }> = ({ assetList }) => (
  <>
    {assetList.map((asset) => (
      <Flex key={asset.id} width={[1, null, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} mb="3" px={[0, null, 2]}>
        <LazyLoad
          once
          resize
          placeholder={<AssetListItemPlaceholder />}
          scrollContainer=".main-content"
        >
          <AssetListItem asset={asset} />
        </LazyLoad>
      </Flex>
    ))}
  </>
);

export const LoadingAssetList: React.FC<{ perPage: number }> = ({ perPage }) => {
  return (
    <>
      {Array.from(Array(perPage).keys()).map((id) => (
        <Flex key={id} width={[1, null, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} mb="3" px={[0, null, 2]}>
          <AssetListItemPlaceholder />
        </Flex>
      ))}
    </>
  );
};

export default AssetList;
