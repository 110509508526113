import { SetFilter, FilterRedux, ACTIONS, FILTERS } from 'types/Filter';

const initialState: FilterRedux = {
  [FILTERS.ASSETS]: {},
  [FILTERS.DOCUMENTS]: {},
};

const filterStateReducer = (state = initialState, action: SetFilter): FilterRedux => {
  switch (action.type) {
    case ACTIONS.SET_FILTER: {
      /* Merging existing filters state with existing filters
         to avoid overriding filters still in use */
      return { ...state, ...action.filter };
    }
    default:
      return state;
  }
};

export default filterStateReducer;
