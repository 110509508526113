/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  verticalAlign,
  VerticalAlignProps,
} from 'styled-system';
import { css } from '@styled-system/css';

interface IconProps extends SpaceProps, ColorProps, TypographyProps, VerticalAlignProps {
  className?: string;
}

const StyledIcon: React.FC<IconProps> = styled('i')(
  css({
    color: 'text',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  }),
  color,
  typography,
  verticalAlign,
  space
);

const Icon: React.FC<IconProps & { icon: string }> = ({ icon, className = '', ...props }) => (
  <StyledIcon className={`icon material-icons ${className}`} {...props}>
    {icon}
  </StyledIcon>
);

export default Icon;
