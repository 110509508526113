/* eslint-disable react/jsx-props-no-spreading */
import React, { DOMAttributes } from 'react';
import styled from 'styled-components';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';
import { css } from '@styled-system/css';

export interface ImgProps extends SpaceProps, LayoutProps, DOMAttributes<ImgProps> {
  className?: string | undefined;
  as?: string | undefined;
  alt?: string | undefined;
  src?: string;
}

const StyledImg: React.FC<ImgProps> = styled('img')(
  css({
    maxWidth: '100%',
    width: 'max-content',
    height: 'auto',
    verticalAlign: 'middle',
  }),
  space,
  layout
);

const Img: React.FC<ImgProps> = ({ alt, src, ...props }) => (
  <StyledImg alt={alt} src={src} {...props} />
);

export default Img;
