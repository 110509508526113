import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FolderListProps, FolderProperties, FoldersAPIRespGetFolders } from 'types/Folders';
import { DocumentListItemProperties } from 'types/Documents';
import { MFC } from 'types/React';
import Routes from 'helpers/Routes';
import FolderAPI from 'api/Folder';
import DocumentAPI from 'api/Document';
import ComponentErrorMessage from 'components/ComponentErrorMessage';
import { updateBreadcrumb, clear } from 'redux/actions/DocumentOrganizer';
import ReduxDocumentOrganizer from 'redux/mappers/DocumentOrganizer';
import { Box } from 'primitives';
import ListItemPlaceholder from './ListItemPlaceholder';
import ListRenderer, { ListType } from './ListRenderer';

interface FoldersAndDocuments extends FoldersAPIRespGetFolders {
  documents: Array<DocumentListItemProperties> | [];
}

const FolderAndDocumentList: MFC<FolderListProps> = ({ isLoading = true, assetId, folderId }) => {
  const { foldersData } = useSelector(ReduxDocumentOrganizer);

  const [foldersList, setFoldersList] = useState<FolderProperties[] | undefined>(
    foldersData.folders.length ? foldersData.folders : undefined
  );
  const [documentsList, setDocumentsList] = useState<DocumentListItemProperties[]>();
  const [isLoadingContent, setIsLoadingContent] = useState(isLoading);

  const isEmpty =
    !isLoadingContent &&
    foldersList &&
    !foldersList.length &&
    documentsList &&
    !documentsList.length;

  /** @const {function} dispatch redux dispatch function */
  const dispatch = useDispatch();

  const { t } = useTranslation('document-organizer');

  /** @const {Object} history react router history object */
  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;

    /**
     * @constant {function} getFoldersAndDocuments
     * Async function that calls both endpoints for Documents and Folders in parallel,
     * Folder Id, and Asset Id are passed by the parent component
     */
    const getFoldersAndDocuments = async (): Promise<FoldersAndDocuments> => {
      const foldersResponse = FolderAPI.getFolders({ assetId, folderId });
      const documentsResponse =
        assetId && folderId ? DocumentAPI.getFolderDocuments({ assetId, folderId }) : [];

      // move the await to the return, this way both calls run asynchronous
      return Object.assign(await foldersResponse, { documents: await documentsResponse });
    };

    /**
     * @constant {function} updateFoldersAndDocuments
     * update the current states for documents and folders lists
     * update breadcrumb by dispatching new redux state
     */
    const updateFoldersAndDocuments = async (): Promise<void> => {
      try {
        if (isSubscribed) {
          setFoldersList(undefined);
          setDocumentsList(undefined);
          setIsLoadingContent(true);
        }

        const { name, number, parent, folders, documents } = await getFoldersAndDocuments();

        dispatch(
          updateBreadcrumb({
            currentFolderName: `${number} ${name}`,
            parentFolderId: parent,
            assetId,
          })
        );
        if (isSubscribed) {
          setFoldersList(folders);
          setDocumentsList(documents);
          setIsLoadingContent(false);
        }
      } catch (e) {
        /**
         * `Folder.getFolders` displays error notification and throws error if fetching failed
         * use that error to redirect to cockpit
         */
        history.push(Routes.Cockpit);
      }
    };

    // if no redux data passed, fetch folders
    if (!foldersData.folders.length) updateFoldersAndDocuments();
    else if (isSubscribed) {
      setIsLoadingContent(false);
      dispatch(
        updateBreadcrumb({
          currentFolderName: `${foldersData.number} ${foldersData.name}`,
          parentFolderId: foldersData.parent,
          assetId,
        })
      );
    }

    // clean up when the component dismounts
    return (): void => {
      isSubscribed = false;
      dispatch(clear());
    };
  }, [assetId, folderId, dispatch, history, foldersData]);

  return (
    <Box
      borderTop="1px solid"
      borderColor="grayLight"
      backgroundColor="white"
      marginBottom="3"
      flex="1"
      boxShadow="default"
    >
      {isEmpty && <ComponentErrorMessage title={t('empty_folder_message')} icon="folder" />}
      {!!foldersList && (
        <ListRenderer list={foldersList} assetId={assetId} type={ListType.folder} />
      )}
      {documentsList && (
        <ListRenderer
          list={documentsList}
          type={ListType.document}
          assetId={assetId}
          folderId={folderId}
        />
      )}
      {isLoadingContent &&
        // TODO: Use subFolderCount from parent folder to build this array
        Array.from(Array(5).keys()).map((id) => <ListItemPlaceholder icon="folder" key={id} />)}
      {isLoadingContent &&
        Array.from(Array(5).keys()).map((id) => (
          <ListItemPlaceholder icon="insert_drive_file" key={id} />
        ))}
    </Box>
  );
};

export default memo(FolderAndDocumentList);

FolderAndDocumentList.whyDidYouRender = true;
