import React, { lazy, memo } from 'react';
import { Route } from 'react-router-dom';
import RootRouter from 'routers/routes/RootRouter';
import CockpitRouter from 'routers/routes/CockpitRouter';
import DocumentOrganizerRouter from 'routers/routes/DocumentOrganizerRouter';
import { MFC } from 'types/React';
import Routes, { Switch } from 'helpers/Routes';
import AuthRoute from './common/AuthRouteHandler';
import PrivateRoute from './common/PrivateRouteHandler';

// lazy load authentication components as we might not always need them
const LoginRouter = lazy(() => import('routers/routes/LoginRouter'));
const ForgotPasswordRouter = lazy(() => import('routers/routes/ForgotPasswordRouter'));
const RecoverPasswordRouter = lazy(() => import('routers/routes/RecoverPasswordRouter'));

const MainRouter: MFC = () => (
  <Switch>
    <Route exact path={Routes.Root} component={RootRouter} />
    <AuthRoute exact path={Routes.LoginPage} component={LoginRouter} name="login" />
    <AuthRoute
      path={Routes.ForgotPassword}
      component={ForgotPasswordRouter}
      name="forgot-password"
    />
    <AuthRoute
      exact
      path={Routes.UserRecoverPassword}
      component={RecoverPasswordRouter}
      name="reset-password"
    />
    <PrivateRoute exact path={Routes.Cockpit} component={CockpitRouter} name="cockpit" />
    <PrivateRoute path={Routes.Assets} component={DocumentOrganizerRouter} name="assets" />
  </Switch>
);

export default memo(MainRouter);

// track component's unnecessary re-renders
MainRouter.whyDidYouRender = true;
