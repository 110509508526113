import { AssetProperties, PaginationProperties, AssetState } from 'types/Assets';
import Request from './API';

export interface AssetAPIRespProperties {
  items: AssetProperties[];
  filtering: string;
  portfolioId?: number;
  stateId?: number;
  pagination: PaginationProperties;
}

export interface AssetAPIGetParams {
  page?: number;
  perPage?: number;
  filter?: string;
  portfolioId?: number;
  stateId?: AssetState;
}
class Asset {
  static baseUrl = '/api/v2/assets';

  static queryParams: Record<string, string | number | undefined> = {
    perPage: 20,
    page: undefined,
    filter: undefined,
  };

  static getAssets = async (perPage: number): Promise<AssetAPIRespProperties> => {
    Asset.queryParams.perPage = perPage;
    return new Request().get(Asset.baseUrl, Asset.queryParams);
  };

  static filterAssets = async (filter?: string): Promise<AssetAPIRespProperties> => {
    Asset.queryParams.filter = filter;
    Asset.queryParams.page = undefined;
    return new Request().get(Asset.baseUrl, Asset.queryParams);
  };

  static goToPage = async (page?: number): Promise<AssetAPIRespProperties> => {
    Asset.queryParams.page = page;
    return new Request().get(Asset.baseUrl, Asset.queryParams);
  };

  static getAsset = async (id: number): Promise<AssetProperties> => {
    const request = new Request();
    request.throwError = true;
    return request.get(`/api/assets/${id}`);
  };
}

export default Asset;
