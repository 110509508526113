import { useState, useEffect, useRef } from 'react';
import Asset from 'api/Asset';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterText } from 'redux/mappers/Filter';
import { getPageNumber } from 'redux/mappers/Pagination';
import { useTranslation } from 'react-i18next';
import { changePageTitle, changeHeaderTitle } from 'redux/actions/Page';
import { AssetProperties, PaginationProperties } from 'types/Assets';
import { FILTERS } from 'types/Filter';
import theme from 'theme';
import useWindowSize from 'helpers/useWindowSize';

const useCockpit = (): {
  isLoading: boolean;
  isFiltered?: boolean;
  assetList: AssetProperties[];
  pagination?: PaginationProperties;
  filterText?: string;
  perPage: number;
} => {
  const [assetList, setAssetList] = useState<AssetProperties[]>([]);
  const [pagination, setPagination] = useState<PaginationProperties>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const filterText = useSelector(getFilterText(FILTERS.ASSETS));
  const currentPage = useSelector(getPageNumber());
  const { innerWidth } = useWindowSize();
  const [perPage, setPerPage] = useState<number>();
  const { t } = useTranslation('cockpit');

  /** @const {function} dispatch redux dispatch function */
  const dispatch = useDispatch();

  // Saving with useRef to avoid triggering a re-render when this value changes
  const isEmpty = useRef(true);

  const defaultPerPage = 20;

  useEffect(() => {
    const sizes = theme.screenSizes;

    let perPageCount = defaultPerPage;

    if (innerWidth < sizes.md) {
      perPageCount = 20;
    } else if (innerWidth > sizes.md && innerWidth < sizes.lg) {
      perPageCount = 30;
    } else if (innerWidth > sizes.lg && innerWidth < sizes.xl) {
      perPageCount = 40;
    } else if (innerWidth > sizes.xl) {
      perPageCount = 50;
    }

    if (perPageCount !== perPage) setPerPage(perPageCount);
  }, [innerWidth, perPage]);

  useEffect(() => {
    dispatch(changePageTitle(t('title')));
    dispatch(changeHeaderTitle(t('title')));
  }, [dispatch, t]);

  useEffect(() => {
    const fetchAssets = async (): Promise<void> => {
      setIsLoading(true);
      const response = await Asset.getAssets(perPage || defaultPerPage);
      setAssetList(response.items);
      setPagination(response.pagination);
      setIsLoading(false);
      isEmpty.current = false;
    };
    if (perPage) fetchAssets();
  }, [perPage]);

  useEffect(() => {
    const goToPage = async (): Promise<void> => {
      setIsLoading(true);
      const response = await Asset.goToPage(currentPage);
      setAssetList(response.items);
      setPagination(response.pagination);
      setIsLoading(false);
      setIsFiltered(true);
    };
    if (currentPage) goToPage();
  }, [currentPage]);

  useEffect(() => {
    const filterAssets = async (): Promise<void> => {
      setIsLoading(true);
      setIsFiltered(false);
      const response = await Asset.filterAssets(filterText);
      setAssetList(response.items);
      setPagination(response.pagination);
      setIsLoading(false);
      setIsFiltered(true);
    };
    if (!isEmpty.current) filterAssets();
  }, [filterText, isEmpty]);

  return {
    isLoading,
    isFiltered,
    assetList,
    pagination,
    filterText,
    perPage: perPage || defaultPerPage,
  };
};
export default useCockpit;
