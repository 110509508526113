import React from 'react';
import { Container } from 'primitives';
import Loading from 'components/Loading';
import { MFC } from 'types/React';
import DocumentPreviewHeader from 'components/DocumentPreviewHeader';
import PageList from 'components/PageList';
import useDocumentPreview from './DocumentPreview.hook';

const DocumentPreview: MFC = () => {
  const { document } = useDocumentPreview();

  return (
    <>
      <DocumentPreviewHeader document={document} />
      <Container className="main-content document-preview">
        {!document && <Loading page />}
        {document && <PageList document={document} />}
      </Container>
    </>
  );
};

export default DocumentPreview;
DocumentPreview.whyDidYouRender = true;
