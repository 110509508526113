import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import ThemeProvider from 'theme/ThemeProvider';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import ReduxStore from 'redux/store';
import 'i18n';
import Loading from 'components/Loading';
import Initializer from 'helpers/Initializer';

const renderApp = (): void => {
  const ArchMobile = (
    <Provider store={ReduxStore.store}>
      <ThemeProvider>
        <ReactNotification />
        <Suspense fallback={<Loading page />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </Provider>
  );

  ReactDOM.render(ArchMobile, document.getElementById('root'));
};

(async (): Promise<void> => {
  // configure react store with empty pre-loaded state
  ReduxStore.configureStore();
  // check user's session and fetch instance configurations
  await Initializer();
  // TODO: persist redux state so we can render app without waiting for initializer
  return renderApp();
})();
