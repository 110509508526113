import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme, { inverted } from './theme';

const GlobalStyle = createGlobalStyle({
  'html, body, #root': {
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    fontFamily: theme.fonts.body,
    overflow: 'hidden',
  },
  '.main-content': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  'input, textarea, button, select, a': {
    '-webkit-tap-highlight-color': 'transparent',
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  'h1, h2, h3, h4, h5, h6': {
    margin: 0,
    color: 'inherit',
  },
});

const DefaultTheme: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
);

export const InvertedTheme: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={inverted}>{children}</ThemeProvider>
  </>
);

export default DefaultTheme;
