import { SetPage, ACTIONS } from 'types/Pagination';

export const setPage = (page: number): SetPage => {
  return {
    type: ACTIONS.SET_PAGE,
    page,
  };
};

export default setPage;
