/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
  variant,
} from 'styled-system';
import { css } from '@styled-system/css';
import { Link as RouterLink } from 'react-router-dom';

interface BaseLinkProps extends SpaceProps, ColorProps, TypographyProps, BorderProps, LayoutProps {
  truncate?: boolean;
  className?: string;
  as?: string;
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to?: any;
  href?: string;
  underlineonhover?: boolean;
}
const StyledLink: React.FC<BaseLinkProps & { block: string; underline?: string }> = styled(
  RouterLink
)(
  css({
    m: 0,
    fontWeight: 'normal',
    fontFamily: 'body',
    fontSize: 'md',
    lineHeight: 'base',
    color: 'text',
    '&:hover, &:focus': {
      color: 'hovered',
    },
  }),
  color,
  typography,
  space,
  border,
  layout,
  variant({
    prop: 'truncate',
    variants: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }),
  variant({
    prop: 'underline',
    variants: {
      true: {
        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
      },
    },
  }),
  variant({
    prop: 'block',
    variants: {
      true: {
        display: 'block',
        width: '100%',
      },
    },
  })
);

const Link: React.FC<BaseLinkProps & { block?: boolean; underline?: boolean }> = ({
  children,
  block = false,
  underline = false,
  to,
  ...props
}) => {
  const isExternal = to?.substr(0, 4) === 'http';
  return (
    <StyledLink
      as={isExternal ? 'a' : undefined}
      href={isExternal ? to : undefined}
      to={isExternal ? undefined : to}
      block={block.toString()}
      underline={underline.toString()}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
