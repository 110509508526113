/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { space, color, border, layout, grid, flexbox, variant } from 'styled-system';
import Flex, { BaseFlexProps } from '../Flex';

interface RowProps extends BaseFlexProps {
  align?: string;
}

const StyledRow: React.FC<RowProps> = styled(Flex)(
  {
    flexDirection: 'row',
    width: '100%',
  },
  variant({
    prop: 'align',
    variants: {
      left: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      right: {
        alignItems: 'end',
      },
    },
  }),
  flexbox,
  space,
  color,
  border,
  layout,
  grid
);

StyledRow.defaultProps = {
  align: 'left',
};

const Row: React.FC<RowProps> = ({ children, ...props }) => (
  <StyledRow {...props}>{children}</StyledRow>
);

export default Row;
