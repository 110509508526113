/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { getHeaderTitle } from 'redux/mappers/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InvertedTheme } from 'theme/ThemeProvider';
import { Flex, Link, Button, Img, Heading, Container, Icon } from 'primitives';
import { BaseFlexProps } from 'primitives/Flex';
import styled from 'styled-components';
import { MFC } from 'types/React';
import { css } from '@styled-system/css';
import { userLogout } from 'redux/actions/Session';
import { setLoadingOverlay } from 'redux/actions/Page';
import Session from 'api/Session';
import i18n from 'i18n';

interface StyledHeaderProps extends BaseFlexProps {
  onBlur?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const StyledHeader: React.FC<StyledHeaderProps> = styled(Flex)(
  css({
    backgroundColor: 'background',
    zIndex: 1,
  })
);

const StyledNavLink = styled(Link)(
  css({
    px: 3,
    py: 2,
    display: 'flex',
    alignItems: 'center',
  })
);

const MainHeader: MFC = () => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const title = useSelector(getHeaderTitle);
  /** @const {function} dispatch redux dispatch function */
  const dispatch = useDispatch();
  const toggleLang = i18n.language === 'en' ? 'de' : 'en';

  const logout = async (event: { preventDefault: () => void }): Promise<void> => {
    event.preventDefault();
    dispatch(setLoadingOverlay(true));
    if (await Session.logOut()) dispatch(userLogout());
    dispatch(setLoadingOverlay(false));
  };

  const handleBlur = (event: React.MouseEvent): void => {
    const { currentTarget } = event;
    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setExpanded(false);
      }
    }, 0);
  };

  return (
    <InvertedTheme>
      <StyledHeader as="header" onBlur={handleBlur} data-test-id="main-header">
        <Container flexDirection={['column', null, null, null, 'row']}>
          <Flex justifyContent="space-between" alignItems="center" flex="1" height="3.5rem">
            <Link to="/cockpit" py="0.75rem" px="3">
              <Img
                size="28px"
                minWidth="28px"
                className="logo"
                src={`${process.env.PUBLIC_URL}/images/logo/icon-negative.svg`}
                alt="Architrave logo"
              />
            </Link>
            <Heading margin="0 auto" truncate>
              {title || t('common:loading')}
            </Heading>

            <Button
              data-test-id="main-header--menu-button"
              aria-controls="basic-navbar-nav"
              onClick={(): void => setExpanded(!expanded)}
              variant="transparent"
              display={['inline-block', null, null, null, 'none']}
            >
              <Icon icon="menu" fontSize="xxl" />
            </Button>
          </Flex>
          <Flex
            flexDirection={['column', null, null, null, 'row']}
            data-test-id="main-header--menu"
            display={[expanded ? 'flex' : 'none', null, null, null, 'flex']}
          >
            <StyledNavLink to="/cockpit">{t('header.cockpit')}</StyledNavLink>
            <StyledNavLink to="#" onClick={logout}>
              {t('header.logout')}
            </StyledNavLink>
            <StyledNavLink
              to="#"
              onClick={(): Promise<Function> => i18n.changeLanguage(toggleLang)}
            >
              {t(`header.${toggleLang}`)}
            </StyledNavLink>
          </Flex>
        </Container>
      </StyledHeader>
    </InvertedTheme>
  );
};

export default MainHeader;
