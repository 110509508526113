export interface NotificationProperties {
  title?: string;
  text?: string;
  type?: NotificationTypes;
}

export enum NotificationTypes {
  success,
  error,
  warning,
}

export default NotificationTypes;
