import { ReduxStates } from 'types/ReduxProps';
import { PageRedux } from 'types/Page';

export const getLoadingOverlay = ({ page }: ReduxStates): boolean => page.loadingState;

export const getHeaderTitle = ({ page }: ReduxStates): string => page.headerTitle;

export const getPageTitle = ({ page }: ReduxStates): string => page.pageTitle;

export const getSSOProviders = ({ page }: ReduxStates): Array<string> => page.SSOProviders;

const getPageProps = ({ page }: ReduxStates): PageRedux => ({
  pageTitle: page.pageTitle,
  headerTitle: page.headerTitle,
  loadingState: page.loadingState,
  SSOProviders: page.SSOProviders,
});

export default getPageProps;
