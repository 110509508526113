import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['common', 'notifications', 'countries'],
    defaultNS: 'common',
    lng: 'de',
    fallbackLng: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    backend: {
      loadPath: '/m/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
