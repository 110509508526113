import React from 'react';
import styled, { css as styledComponentsCss, keyframes } from 'styled-components';
import { Box, Container, Icon } from 'primitives';

interface PlaceholderProps {
  icon: string;
}

const fadeIn = keyframes`
  from {
    opacity: 60%;
  }
`;

const AnimatedBox = styled(Box)`
  animation: ${styledComponentsCss`${fadeIn} 0.6s infinite alternate;`};
`;

const ListItemPlaceholder: React.FC<PlaceholderProps> = ({ icon }) => (
  <Box borderBottom="1px solid" borderColor="grayLight">
    <Container>
      <AnimatedBox
        borderBottom="1px solid"
        borderColor="grayLight"
        height="3.5rem"
        display="flex"
        alignItems="center"
        px="3"
        className="item--loading"
      >
        <Icon icon={icon} color="gray" pr="3" />
        <Box flex="1" backgroundColor="grayLight" height="0.75rem" />
      </AnimatedBox>
    </Container>
  </Box>
);

export default ListItemPlaceholder;
