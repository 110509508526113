import React from 'react';
import { Box } from 'primitives';
import { DocumentProperties } from 'types/Documents';
import ComponentErrorMessage from 'components/ComponentErrorMessage';
import LazyLoad from 'react-lazyload';
import { MFC } from 'types/React';
import PageLoader, { PagePlaceholder } from './PageLoader';
import usePageListErrorMessage from './PageListErrorMessage.hook';

interface Props {
  document: DocumentProperties;
}

const PageList: MFC<Props> = ({ document }) => {
  const { pageCount, id } = document;
  const errorMessage = usePageListErrorMessage(document);

  return (
    <>
      {errorMessage && (
        <ComponentErrorMessage
          title={errorMessage.title}
          text={errorMessage.text}
          icon={errorMessage.icon}
        />
      )}
      {!errorMessage && (
        <Box mt={3} mx={3}>
          {Array.from(Array(pageCount).keys()).map((pageNumber) => {
            return (
              <Box mb={3} boxShadow="default" key={pageNumber}>
                <LazyLoad
                  once
                  resize
                  placeholder={<PagePlaceholder />}
                  scrollContainer=".document-preview"
                >
                  <PageLoader id={id} pageNumber={pageNumber} pageCount={pageCount} />
                </LazyLoad>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default PageList;
PageList.whyDidYouRender = true;
