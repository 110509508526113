/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
  variant,
  position,
  PositionProps,
} from 'styled-system';
import { Spinner } from 'primitives';
import { css } from '@styled-system/css';

interface ButtonProps
  extends SpaceProps,
    ColorProps,
    BorderProps,
    LayoutProps,
    TypographyProps,
    PositionProps {
  className?: string | undefined;
  as?: string | undefined;
  href?: string | undefined;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'primary-outline' | 'secondary-outline' | 'transparent';
  type?: 'button' | 'submit' | 'reset';
  block?: boolean;
  transition?: 'string';
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | null | undefined;
}

const StyledButton: React.FC<ButtonProps> = styled('button')(
  css({
    fontSize: 'md',
    paddingX: '3',
    paddingY: '2',
    borderRadius: '0.25rem',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    position: 'relative',
    border: 'none',
    cursor: 'pointer',
    lineHeight: '1em',
    '&:focus, &:hover': {
      outline: 'none',
      boxShadow: 'none',
    },
    '&:disabled': {
      pointerEvents: 'none',
    },
  }),
  variant({
    variants: {
      primary: {
        color: 'blueDark3',
        backgroundColor: 'primary',
        transition: 'default',
      },
      secondary: {
        color: 'blueDark3',
        backgroundColor: 'secondary',
        borderColor: 'secondary',
        transition: 'default',
      },
      'primary-outline': {
        color: 'primary',
        backgroundColor: 'transparent',
        borderColor: 'primary',
        borderWidth: '2px',
        borderStyle: 'solid',
        transition: 'default',
        '&:hover, &:focus': {
          color: 'blueDark3',
          backgroundColor: 'primary',
        },
      },
      'secondary-outline': {
        color: 'secondary',
        backgroundColor: 'transparent',
        borderColor: 'secondary',
        borderWidth: '2px',
        borderStyle: 'solid',
        transition: 'default',
        '&:hover, &:focus': {
          color: 'blueDark3',
          backgroundColor: 'secondary',
        },
      },
      transparent: {
        color: 'inherit',
        backgroundColor: 'transparent',
        border: 'none',
      },
    },
  }),
  variant({
    prop: 'block',
    variants: {
      true: {
        display: 'block',
        width: '100%',
      },
    },
  }),
  space,
  color,
  border,
  layout,
  typography,
  position
);

StyledButton.defaultProps = {
  variant: 'primary',
  type: 'button',
};

const Button: React.FC<ButtonProps & { loading?: boolean }> = ({
  children,
  block = false,
  loading = false,
  ...props
}) => (
  <StyledButton {...props} block={block}>
    {loading && <Spinner position="absolute" left="3" top="50%" mt="-0.6em" size="1.2em" />}
    {children}
  </StyledButton>
);

export default Button;
