/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Icon, Row, Flex, Container } from 'primitives';
import { MFC } from 'types/React';
import { PaginationProperties } from 'types/Assets';
import usePagination from './Pagination.hook';

const Pagination: MFC<{ pagination: PaginationProperties; isLoading: boolean }> = ({
  pagination,
  isLoading,
}) => {
  const { nextPage, previousPage, currentPage } = pagination;
  const {
    filteredPages,
    filteredNextPage,
    filteredPreviousPage,
    activePage,
    goToPage,
  } = usePagination(pagination);

  return (
    <Row data-test-id="pagination" py={3}>
      <Container flexDirection="row" justifyContent="center">
        <Button
          px={2}
          py={0}
          variant="transparent"
          data-test-id="page-button--previous"
          disabled={!previousPage}
          onClick={(): void => goToPage(currentPage - 1)}
        >
          <Icon color={previousPage ? 'text' : 'gray'} icon="chevron_left" />
        </Button>
        {filteredPreviousPage && (
          <Button
            px={2}
            py={0}
            fontWeight="normal"
            color="text"
            variant="transparent"
            data-test-id="page-button--filter-previous"
            disabled={isLoading}
            onClick={(): void => goToPage(filteredPreviousPage)}
          >
            ...
          </Button>
        )}
        <Flex>
          {filteredPages.map((page) => (
            <Button
              px=".75rem"
              py={1}
              fontWeight="normal"
              fontSize="xs"
              color={page === activePage ? 'white' : 'text'}
              key={page}
              variant={page === activePage ? 'primary' : 'transparent'}
              data-test-id="page-button"
              disabled={isLoading}
              onClick={(): void => goToPage(page)}
            >
              {page}
            </Button>
          ))}
        </Flex>
        {filteredNextPage && (
          <Button
            px={2}
            py={0}
            fontWeight="normal"
            color="text"
            variant="transparent"
            data-test-id="page-button--filter-next"
            disabled={isLoading}
            onClick={(): void => goToPage(filteredNextPage)}
          >
            ...
          </Button>
        )}
        <Button
          px={2}
          py={0}
          variant="transparent"
          data-test-id="page-button--next"
          disabled={!nextPage}
          onClick={(): void => goToPage(currentPage + 1)}
        >
          <Icon color={nextPage ? 'text' : 'gray'} icon="chevron_right" />
        </Button>
      </Container>
    </Row>
  );
};

export default Pagination;
