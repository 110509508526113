/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
  grid,
  GridProps,
  flex,
  FlexProps,
  flexbox,
  FlexboxProps,
  boxShadow,
  BoxShadowProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
} from 'styled-system';

export interface BoxProps
  extends SpaceProps,
    ColorProps,
    BorderProps,
    LayoutProps,
    GridProps,
    FlexboxProps,
    FlexProps,
    BoxShadowProps,
    PositionProps,
    TypographyProps {
  className?: string;
  as?: string;
}

const StyledBox: React.FC<BoxProps> = styled('div')(
  {
    boxSizing: 'border-box',
  },
  space,
  color,
  border,
  layout,
  grid,
  flex,
  flexbox,
  boxShadow,
  position,
  typography
);

const Box: React.FC<BoxProps> = ({ children, ...props }) => (
  <StyledBox {...props}>{children}</StyledBox>
);

export default Box;
