import { ACTIONS, SessionProperties, UserLoginAction, UserLogoutAction } from 'types/Session';

export const userLogin = (session: SessionProperties): UserLoginAction => {
  return {
    type: ACTIONS.LOGIN_USER,
    session,
  };
};

export const userLogout = (): UserLogoutAction => ({
  type: ACTIONS.LOGOUT_USER,
});
