import React, { memo } from 'react';
import { Container, Flex, Link, Icon, Heading } from 'primitives';
import { useTranslation } from 'react-i18next';
import Truncate from 'helpers/Truncate';
import { MFC } from 'types/React';
import { DocumentProperties } from 'types/Documents';
import { Permissions } from 'types/Folders';

interface Props {
  document?: DocumentProperties;
}

const DocumentPreviewHeader: MFC<Props> = ({ document }) => {
  const { t } = useTranslation();
  const href = document ? `/assets/${document.asset.id}/folders/${document.folder.id}` : '';
  const downloadLink = `/api/documents/${document?.id}/download`;
  const downloadOriginal = document?.folder.groupFolderRights?.[Permissions.DOWNLOAD_ORIGINAL];

  return (
    <Container
      data-test-id="breadcrumbs"
      flexDirection="row"
      alignItems="center"
      flex="0 0 auto"
      backgroundColor="white"
      boxShadow="default"
      zIndex={1}
    >
      <Link to={href} py="0.75rem" px="3" data-test-id="close-button">
        <Icon verticalAlign="middle" icon="clear" />
      </Link>
      <Flex data-test-id="current-folder" flex="1" overflow="hidden">
        <Heading as="h2" width="100%" lineHeight="md">
          {!document && t('common:loading')}
          {document && <Truncate text={document.name} />}
        </Heading>
      </Flex>
      {downloadOriginal && (
        <Link as="a" href={downloadLink} py="0.75rem" px="3" data-test-id="download-button">
          <Icon verticalAlign="middle" icon="vertical_align_bottom" />
        </Link>
      )}
    </Container>
  );
};

export default memo(DocumentPreviewHeader);

// this is required for unit testing as enzyme doesn't handle state change on memo(ed) components
export { DocumentPreviewHeader as DocumentPreviewHeaderComponent };

DocumentPreviewHeader.whyDidYouRender = true;
