import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import NotificationManager from 'helpers/NotificationManager';
import { MFC } from 'types/React';
import { NotificationTypes } from 'types/Notifications';

import { useTranslation } from 'react-i18next';

const PageNotFound: MFC = () => {
  useEffect(() => {
    NotificationManager.addNotification({
      type: NotificationTypes.error,
      title: 'page_not_found',
      text: 'you_have_been_redirected',
    });
  });
  return <Redirect to="/cockpit" />;
};

const PageNotFoundRouter: MFC = () => {
  const { t } = useTranslation('notifications');
  return <PageNotFound />;
};

export default PageNotFoundRouter;
