import { FoldersAPIRespGetFolders } from './Folders';

// redux
export enum ACTIONS {
  BREADCRUMB = 'BREADCRUMB',
  FOLDERSLIST = 'FOLDERSLIST',
  CLEAR = 'CLEAR',
}
export interface DocumentOrganizerRedux {
  breadcrumbs: BreadcrumbsProps;
  foldersData: FoldersAPIRespGetFolders;
}
export interface UpdateBreadcrumbAction {
  type: ACTIONS.BREADCRUMB;
  payload: BreadcrumbsProps;
}

export interface UpdateFoldersListAction {
  type: ACTIONS.FOLDERSLIST;
  payload: FoldersAPIRespGetFolders;
}

export interface ClearAction {
  type: ACTIONS.CLEAR;
}

export type DocumentOrganizerActionTypes =
  | UpdateBreadcrumbAction
  | UpdateFoldersListAction
  | ClearAction;

// components
export type BreadcrumbsProps = {
  currentFolderName?: string;
  parentFolderId?: number;
  assetId?: number | string;
};
