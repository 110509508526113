import {
  UpdateBreadcrumbAction,
  BreadcrumbsProps,
  UpdateFoldersListAction,
  ClearAction,
  ACTIONS,
} from 'types/DocumentOrganizer';
import { FoldersAPIRespGetFolders } from 'types/Folders';

export const updateBreadcrumb = (payload: BreadcrumbsProps): UpdateBreadcrumbAction => ({
  type: ACTIONS.BREADCRUMB,
  payload,
});

export const updateFoldersList = (payload: FoldersAPIRespGetFolders): UpdateFoldersListAction => ({
  type: ACTIONS.FOLDERSLIST,
  payload,
});

export const clear = (): ClearAction => ({
  type: ACTIONS.CLEAR,
});
