import React from 'react';
import { Flex, Container } from 'primitives';
import { MFC } from 'types/React';
import { FILTERS } from 'types/Filter';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer';
import ComponentErrorMessage from 'components/ComponentErrorMessage';
import FilterInput from 'components/FilterInput';
import Pagination from 'components/Pagination';
import useCockpit from './Cockpit.hook';
import AssetList, { LoadingAssetList } from './AssetList';

const Cockpit: MFC = () => {
  const { assetList, pagination, isLoading, isFiltered, perPage } = useCockpit();
  const { t } = useTranslation('cockpit');

  return (
    <>
      <FilterInput
        nameSpace={FILTERS.ASSETS}
        title={t('filter.title')}
        placeholder={t('filter.placeholder')}
        isLoading={isLoading}
      />
      {/* The container display property must be set to block for safari to calculate height */}
      <Container display="block">
        <Flex
          flex="1"
          flexDirection={['column', 'column', 'row']}
          flexWrap="wrap"
          mt="3"
          px="3"
          width="100%"
        >
          {!isLoading && !assetList.length && (
            <ComponentErrorMessage
              title={isFiltered ? t('no_assets_match_text') : t('there_are_no_assets')}
              icon="apartment"
            />
          )}
          {isLoading && <LoadingAssetList perPage={perPage} />}
          {!isLoading && assetList.length !== 0 && <AssetList assetList={assetList} />}
        </Flex>
      </Container>
      {pagination && <Pagination pagination={pagination} isLoading={isLoading} />}
      <Footer />
    </>
  );
};
export default Cockpit;

// track component's unnecessary re-renders
Cockpit.whyDidYouRender = true;
