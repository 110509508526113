import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterText } from 'redux/mappers/Filter';
import { setFilter } from 'redux/actions/Filter';
import { useTranslation } from 'react-i18next';
import { FILTERS } from 'types/Filter';

const useFilterInput = (nameSpace: FILTERS) => {
  const { t } = useTranslation();
  const filterState = useSelector(getFilterText(nameSpace));
  const [filterText, setFilterText] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const dispatch = useDispatch();

  // Save input text to global state
  useEffect(() => {
    const filterData = { [nameSpace]: { text: filterText } };

    if (filterText.length === 2 || filterText.length === 1) return;
    // Using timeout to avoid triggering too many re-renders
    const timer = setTimeout(() => {
      dispatch(setFilter(filterData));
    }, 500);
    // Clear timeout when component demounts
    // eslint-disable-next-line consistent-return
    return (): void => clearTimeout(timer);
  }, [filterText, dispatch, nameSpace]);

  // Using saved global filter state to fill text input
  useEffect(() => {
    if (filterState && !hasFocus) {
      setFilterText(filterState || '');
      setHasFocus(true);
    }
  }, [filterState, hasFocus]);

  const clearFilter = (): void => {
    const filterData = { [nameSpace]: { text: '' } };
    setFilterText('');
    setHasFocus(false);
    dispatch(setFilter(filterData));
  };

  return {
    filterText,
    setFilterText,
    clearFilter,
    hasFocus,
    setHasFocus,
    t,
  };
};

export default useFilterInput;
